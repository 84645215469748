import { Link } from 'react-router-dom';
import Anchor from '../../../components/common/Anchor';
import { Button } from '../../../components/common/Button';
import { IconName } from '../../../components/common/Icon';
import ListPageItem from '../../../components/common/ListPageItem';
import { IDiecezjaListItem } from '../../../lib/diecezje';
import { Url } from '../../../router/urls';

export function DiecezjaListItem({
  diecezja,
  index,
}: {
  diecezja: IDiecezjaListItem;
  index: number;
}) {
  const detailsLink = Url.diecezje.single(diecezja.id);

  return (
    <ListPageItem>
      <ListPageItem.IndexCol index={index + 1} />
      <ListPageItem.DetailsCol>
        <ListPageItem.DetailsRow>
          <Link to={detailsLink}>
            <ListPageItem.PrimaryDetail value={diecezja.nazwa} />
          </Link>
        </ListPageItem.DetailsRow>

        <ListPageItem.DetailsRow>
          <ListPageItem.Detail
            label="Liczba parafii"
            value={diecezja.liczbaParafii}
          />

          <ListPageItem.Detail
            label="Liczba księży"
            value={diecezja.liczbaKsiezy}
          />
        </ListPageItem.DetailsRow>

        <ListPageItem.DetailsRow>
          <ListPageItem.Detail
            label="Telefon"
            value={
              <Anchor type="tel" href={diecezja.telefon}>
                {diecezja.telefon}
              </Anchor>
            }
          />

          <ListPageItem.Detail
            label="Adres"
            value={
              <Anchor type="address" href={diecezja.adres}>
                {diecezja.adres}
              </Anchor>
            }
          />
        </ListPageItem.DetailsRow>
      </ListPageItem.DetailsCol>

      <ListPageItem.ButtonsCol>
        {/* @ts-ignore */}
        <Button icon={IconName.Eye} as={Link as any} to={detailsLink}>
          Szczegóły
        </Button>
      </ListPageItem.ButtonsCol>
    </ListPageItem>
  );
}
