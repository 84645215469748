import { authorizedRouter, unauthorizedRouter } from './router';

import { Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { RouterProvider as LibRouterProvider } from 'react-router-dom';
import { queryKeys } from '../entrypoint/react-query';
import { getDaneSesji } from '../lib/auth';

export function RouterProvider() {
  const { data: daneSesji, isLoading: isLoadingDaneSesji } = useQuery(
    queryKeys.daneSesji,
    getDaneSesji,
  );

  if (isLoadingDaneSesji) {
    return (
      <div className="d-flex justify-content-center vh-100 align-items-center">
        <Spinner />
      </div>
    );
  }

  if (daneSesji?.czyZalogowano) {
    return <LibRouterProvider router={authorizedRouter} />;
  } else {
    return <LibRouterProvider router={unauthorizedRouter} />;
  }
}
