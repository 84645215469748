import { Layout } from '../../components/Layout';
import { PageTitle } from '../../components/PageTitleContext';
import Anchor from '../../components/common/Anchor';
import { AsyncContent } from '../../components/common/AsyncContent';
import LabeledInfo from '../../components/common/LabeledInfo';
import { queryKeys } from '../../entrypoint/react-query';
import { getDiecezja } from '../../lib/diecezje';
import { useIdParam } from '../../router/router';

export function DiecezjaView() {
  const id = useIdParam();

  return (
    <Layout>
      <AsyncContent
        queryKey={queryKeys.diecezje.single(id)}
        queryFn={() => getDiecezja(id)}
        renderContent={(diecezja) => (
          <>
            <div>
              <LabeledInfo label="Liczba parafii">
                {diecezja.liczbaParafii}
              </LabeledInfo>

              <LabeledInfo label="Liczba księży">
                {diecezja.liczbaKsiezy}
              </LabeledInfo>

              <LabeledInfo label="Patronowie">
                {diecezja.patronowie}
              </LabeledInfo>

              <h4>Dane teleadresowe</h4>
              <LabeledInfo label="Adres">
                <Anchor type="address" href={diecezja.adres}>
                  {diecezja.adres}
                </Anchor>
              </LabeledInfo>

              <LabeledInfo label="Email">
                <Anchor type="email" href={diecezja.email}>
                  {diecezja.email}
                </Anchor>
              </LabeledInfo>

              <LabeledInfo label="Telefon">
                <Anchor type="tel" href={diecezja.telefon}>
                  {diecezja.telefon}
                </Anchor>
              </LabeledInfo>

              <LabeledInfo label="Strona internetowe">
                <Anchor type="www" href={diecezja.stronaWWW}>
                  {diecezja.stronaWWW}
                </Anchor>
              </LabeledInfo>

              <LabeledInfo label="Godziny urzędowania Kurii">
                {diecezja.godzinyUrzedowaniaKurii}
              </LabeledInfo>

              <LabeledInfo label="NIP">{diecezja.nip}</LabeledInfo>
            </div>

            <PageTitle title={diecezja.nazwa} />
          </>
        )}
      />
    </Layout>
  );
}
