import classNames from 'classnames/bind';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Nil } from '../../../helpers/types';
import Icon from '../Icon';
import { TIconName } from '../Icon/icons';
import styles from './ProfilePicture.scss';

const cx = classNames.bind(styles);

export type ProfilePictureProps = Omit<
  React.HTMLProps<HTMLDivElement>,
  'src' | 'ref'
> & {
  src: string | Nil;
  icon?: TIconName | Nil;
  deadRibbon?: boolean;
  square?: boolean;
  isLoading?: boolean;
  className?: string;
  size?: number;
};

export const DEFAULT_PROFILE_PICTURE_WIDTH = 80;

const ProfilePicture = ({
  src,
  srcSet,
  className,
  deadRibbon,
  icon,
  square,
  isLoading,
  size = DEFAULT_PROFILE_PICTURE_WIDTH,
  ...props
}: ProfilePictureProps) => {
  const hasSrc = Boolean(src || srcSet);

  return (
    <div
      {...props}
      style={{ width: size, height: size }}
      className={cx(className, 'profilePicture', { deadRibbon, square })}
    >
      {hasSrc && (
        <img className={cx('image')} src={src || undefined} srcSet={srcSet} />
      )}
      {!!icon && !hasSrc && (
        <span className={cx('iconContainer')}>
          <Icon icon={icon} />
        </span>
      )}
      {!icon && !hasSrc && isLoading && <Spinner />}
    </div>
  );
};

export default React.memo(ProfilePicture);
