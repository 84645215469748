import React from 'react';
import { Nil } from '../../../helpers/types';

enum AnchorType {
  Email = 'email',
  Tel = 'tel',
  Address = 'address',
  Coords = 'coords',
  Www = 'www',
}

export type AnchorProps = Omit<React.HTMLProps<HTMLAnchorElement>, 'href'> &
  (TextAnchorProps | CoordsAnchorProps);

interface TextAnchorProps {
  type: `${Exclude<AnchorType, AnchorType.Coords>}`;
  href: string | Nil;
}

interface CoordsAnchorProps {
  type: `${AnchorType.Coords}`;
  lat: string;
  lng: string;
  href?: Nil;
}

const typesPropsMutators = {
  www: (props: AnchorProps) => {
    let href = props.href || '#';
    if (href.indexOf('www.') === 0) {
      href = `http://${href}`;
    }
    return {
      href,
      target: '_blank',
      rel: 'noopener noreferrer',
    };
  },
};

export const Anchor = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  (props, ref) => {
    const { children, type } = props;

    if (type === 'address') {
      return (
        <AddressLink {...props} ref={ref} kind="adress" address={props.href} />
      );
    }
    if (type === 'coords') {
      return (
        <AddressLink
          {...props}
          ref={ref}
          kind="coords"
          lat={props.lat}
          lng={props.lng}
        />
      );
    }

    if (type === 'email') {
      return <EmailLink email={props.href} />;
    }
    if (type === 'tel') {
      return <PhoneLink phone={props.href} />;
    }

    const linkContent = children || props.href || '';
    const finalProps = typesPropsMutators[type]
      ? typesPropsMutators[type](props)
      : { ...props };

    const hasNoData = !linkContent && !finalProps.href;

    const finalHref = formatFinalHref(
      props.type as AnchorType,
      finalProps.href,
    );

    return (
      <a {...finalProps} href={finalHref}>
        {hasNoData ? 'Brak danych' : linkContent}
      </a>
    );
  },
);

function formatFinalHref(type: AnchorType, val: string | Nil) {
  if (!val) {
    return '#';
  } else if (type === AnchorType.Www) {
    if (val.match(/^http[s]*:\/\/[\w]+/i)) {
      return val;
    } else {
      return `http://${val}`;
    }
  } else {
    return val;
  }
}

export const EmailLink = React.forwardRef<
  HTMLAnchorElement,
  Omit<React.HTMLProps<HTMLAnchorElement>, 'href'> & {
    email: string | Nil;
  }
>(function _EmailLink({ email, ...props }, ref) {
  return (
    <a {...props} ref={ref} href={email ? `mailto:${email}` : '#'}>
      {email}
    </a>
  );
});

export const PhoneLink = React.forwardRef<
  HTMLAnchorElement,
  Omit<React.HTMLProps<HTMLAnchorElement>, 'href'> & {
    phone: string | Nil;
  }
>(function _PhoneLink({ phone, ...props }, ref) {
  const hasNoData = !phone;

  const Tag = hasNoData ? 'span' : 'a';

  return (
    <Tag
      {...props}
      ref={ref}
      href={
        hasNoData
          ? undefined
          : `tel:${phone!.replace(new RegExp('[^0-9]', 'g'), '')}`
      }
    >
      {hasNoData ? '----' : phone}
    </Tag>
  );
});

export const AddressLink = React.forwardRef<
  HTMLAnchorElement,
  Omit<React.HTMLProps<HTMLAnchorElement>, 'href'> &
    (
      | {
          address: string | Nil;
          lng?: never;
          lat?: never;
        }
      | {
          address?: never;
          lng: string | Nil;
          lat: string | Nil;
        }
    )
>(function _PhoneLink(props, ref) {
  const hasNoData = !props.address && !props.lat && !props.lng;

  const Tag = hasNoData ? 'span' : 'a';

  return (
    <Tag
      {...props}
      rel="noopener noreferrer"
      target="_blank"
      ref={ref}
      href={
        hasNoData
          ? undefined
          : props.lat
          ? `https://google.com/maps/place/${props.lat},${props.lng}`
          : `https://maps.google.com/maps?q=${encodeURIComponent(
              props.address!,
            )}`
      }
    >
      {hasNoData
        ? '---'
        : props.lat
        ? `${props.lat} ${props.lng}`
        : props.address}
    </Tag>
  );
});

export default Anchor;
