import { FetchError, ItemCount } from '../../../components/common/ListMessages';

import { useInfiniteQuery } from 'react-query';
import { Layout } from '../../../components/Layout';
import DynamicFilter from '../../../components/common/DynamicFilter/DynamicFilter';
import { VirtualList } from '../../../components/common/VirtualList';
import { queryKeys } from '../../../entrypoint/react-query';
import { useUrlFiltryISortowanie } from '../../../hooks/useUrlFiltryISortowanie';
import { getListaDiecezji } from '../../../lib/diecezje';
import { pagesToItems } from '../../../lib/fetcher';
import { DiecezjaListItem } from './DiecezjaListItem';

export function DiecezjeView() {
  const { filtryISortowanie, setFiltryISortowanie } = useUrlFiltryISortowanie();

  const { data, refetch, isError, fetchNextPage, isFetching } =
    useInfiniteQuery(
      queryKeys.diecezje.list(filtryISortowanie),
      (ctx) => getListaDiecezji(),
      { getNextPageParam: (page) => page.nastepnaStronaIndeks },
    );

  const diecezje = pagesToItems(data?.pages);

  return (
    <Layout pageTitle="Diecezje">
      <VirtualList
        renderHeader={() => (
          <div className="d-flex flex-column gap-2 pb-3">
            <ItemCount count={data?.pages?.[0]?.liczbaWynikow} loadable />

            <DynamicFilter
              initialValues={filtryISortowanie}
              onChange={setFiltryISortowanie}
              konfiguracjaFiltrow={{
                filtryDefinicje: [],
                sortowanieDefinicje: [],
              }}
            />
          </div>
        )}
        noItemsRenderer={
          isError ? () => <FetchError onRetry={refetch} /> : null
        }
        isInfinite
        loadMoreRows={fetchNextPage}
        isLoading={isFetching}
        itemCount={diecezje?.length}
        renderChild={({ index }) => (
          <DiecezjaListItem diecezja={diecezje![index]!} index={index} />
        )}
      />
    </Layout>
  );
}
