import classNames from 'classnames/bind';
import styles from './Skeleton.scss';

const cx = classNames.bind(styles);

interface SingleSkeletonProps {
  height?: number;
  width?: number;
  gap?: number;
  maxHeight?: boolean;
}

export function Skeleton({
  lines,
  ...props
}: { lines?: number } & SingleSkeletonProps) {
  if (typeof lines === 'number') {
    return (
      <div
        className="d-flex flex-column gap-3 w-100"
        style={{ gap: props.gap }}
      >
        {Array(lines)
          .fill(true)
          .map((_, index) => (
            <SingleSkeleton key={index} {...props} />
          ))}
      </div>
    );
  } else {
    return <SingleSkeleton {...props} />;
  }
}

function SingleSkeleton(props: SingleSkeletonProps) {
  return (
    <div
      className={cx('skeleton')}
      style={{
        height: props.maxHeight ? '100%' : props.height,
        width: props.width,
        borderRadius: '15px',
      }}
    />
  );
}
