import { Col, Form, Row } from 'react-bootstrap';
import { Nil, ReactChild } from '../../../helpers/types';
import { FiltryISortowanie, KonfiguracjaFiltrow } from '../../../lib/Filtry';
import { FieldContainer, FieldLabel } from '../FieldContainer';

import classNames from 'classnames/bind';
import React from 'react';
import { useNewestNotNull } from '../../../hooks/utils';
import { Button } from '../Button';
import { Input } from '../inputs/Input';
import styles from './DynamicFilter.scss';
import { DynamicFilterCustomField } from './types';

const cx = classNames.bind(styles);

export const ALL_FIELDS_FIELD_ID = 'wszystkie';

const DynamicFilter = (props: DynamicFilterProps) => {
  const konfiguracjaFiltrow = useNewestNotNull(props.konfiguracjaFiltrow);

  const [isOpen, setIsOpen] = React.useState(false);

  const handleSubmit = (newValue: FiltryISortowanie) =>
    props.onChange(newValue);

  const [localValue, setLocalValue] = React.useState(props.initialValues || {});

  const czySaZaawansowaneFiltry =
    !konfiguracjaFiltrow ||
    (konfiguracjaFiltrow?.filtryDefinicje &&
      konfiguracjaFiltrow?.filtryDefinicje?.length > 0);

  const getWartoscFiltraOId = (id: string) => {
    return localValue.Filtry?.find((x) => x.Id === id)?.Wartosc;
  };

  const setWartoscFiltraOId = (id: string, nowaWartosc: string | Nil) => {
    setLocalValue((prev) => ({
      ...prev,
      Filtry: [
        ...(prev.Filtry?.filter((filtr) => filtr.Id !== id) || []),
        ...(nowaWartosc ? [{ Id: id, Wartosc: nowaWartosc }] : []),
      ],
    }));
  };

  const opcjeSortowania = konfiguracjaFiltrow?.sortowanieDefinicje?.flatMap(
    (opcja) => [
      {
        id: opcja.id,
        nazwa: `${opcja.nazwa} - rosnąco`,
        czyMalejaco: false,
      },
      {
        id: opcja.id,
        nazwa: `${opcja.nazwa} - malejąco`,
        czyMalejaco: true,
      },
    ],
  );

  const czyJestSortowanie = opcjeSortowania && opcjeSortowania.length > 0;

  return (
    <Form
      className={cx('form')}
      data-test="collapse-filter"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(localValue);
      }}
    >
      <Row form>
        <Col md={czyJestSortowanie ? '8' : '12'}>
          <FieldContainer label="Wyszukiwanie">
            <Input
              className={cx('mb-2')}
              type="text"
              data-testid="filter-wszystkie"
              name="wyszukiwanie"
              value={localValue?.FiltrWszystkiePola}
              onChange={(e) =>
                setLocalValue({
                  ...localValue,
                  FiltrWszystkiePola: e.target.value,
                })
              }
            />
          </FieldContainer>
        </Col>
        <Col
          md="auto"
          className={cx('text-right', 'align-self-end')}
          style={{ marginLeft: 'auto' }}
        >
          <Button
            variant="primary"
            type="submit"
            data-testid="collapse-filter-submit"
            className={cx('submit')}
            style={{ marginLeft: 'auto' }}
          >
            Zastosuj
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

function FilterFieldContainer({
  label,
  children,
}: {
  label: string;
  children: ReactChild;
}) {
  return (
    <Col md="4" xl="3" className="pb-2">
      <FieldLabel label={label} />

      {children}
    </Col>
  );
}

export type DynamicFilterProps = {
  initialValues?: FiltryISortowanie;
  onChange: (newValue: FiltryISortowanie) => void;
  konfiguracjaFiltrow: KonfiguracjaFiltrow | Nil;
  renderCustomFields?: DynamicFilterCustomField[] | Nil;
};

export default DynamicFilter;
