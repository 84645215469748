import { Link } from 'react-router-dom';
import { PodmiotTypId } from '../../lib/misc';
import { Url } from '../../router/urls';

export interface PodmiotLinkProps {
  podmiot: {
    id: string;
    nazwa: string;
    typId: PodmiotTypId;
  };
}

export function PodmiotLink(props: PodmiotLinkProps) {
  const link = resolveLink(props.podmiot);

  return <Link to={link}>{props.podmiot.nazwa}</Link>;
}

function resolveLink(podmiot: PodmiotLinkProps['podmiot']) {
  switch (podmiot.typId) {
    case PodmiotTypId.Diecezja:
      return Url.home;
    case PodmiotTypId.Kontrahent:
      return Url.home;
    case PodmiotTypId.Osoba:
      return Url.home;
    case PodmiotTypId.Parafia:
      return Url.home;
  }
}
