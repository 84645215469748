import type * as React from 'react';

export function refsToCallbackRef<T = any>(
  refs: Array<undefined | React.MutableRefObject<T> | React.LegacyRef<T>>,
): React.RefCallback<T> {
  return (value) => {
    refs
      .filter((r) => !!r)
      .forEach((ref) => {
        if (typeof ref === 'function') {
          ref(value);
        } else if (ref != null) {
          (ref as React.MutableRefObject<T | null>).current = value;
        }
      });
  };
}
