import 'bootstrap/dist/css/bootstrap.min.css';
import './entrypoint/index.scss';

import App from './entrypoint/App';
import React from 'react';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
