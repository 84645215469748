import { Nil, ReactChild } from '../../../helpers/types';

import classNames from 'classnames';
import _isNil from 'lodash/isNil';
import React from 'react';
import { refsToCallbackRef } from '../../../helpers/mergeRefs';

export const InputTextAdornment = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & React.HTMLProps<HTMLSpanElement>) => {
  return (
    <span
      {...props}
      className={classNames('input-group-text', props.className)}
    >
      {children}
    </span>
  );
};
export const InputAdornment = React.memo(function ({
  children,
  endAdornment,
  startAdornment,
  ...props
}: {
  children: React.ReactElement;
  endAdornment?: ReactChild;
  startAdornment?: ReactChild;
} & React.HTMLProps<HTMLDivElement>) {
  return (
    <div {...props} className={classNames('input-group', props?.className)}>
      {startAdornment && (
        <div className="input-group-prepend">{startAdornment}</div>
      )}
      {children}
      {endAdornment && <div className="input-group-append">{endAdornment}</div>}
    </div>
  );
});

export type InputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'value'
> & {
  value?: React.HTMLProps<HTMLInputElement>['value'] | Nil;
  invalid?: boolean;
  withTooltip?: boolean;
  mask?: string;
  focusDependency?: any;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { invalid, withTooltip, mask, className, focusDependency, ...props },
    ref,
  ) => {
    const localRef = React.useRef<HTMLInputElement>();

    React.useEffect(() => {
      if (!_isNil(focusDependency)) {
        localRef.current?.focus();
      }
    }, [JSON.stringify(focusDependency)]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props.type === 'number') {
        if (isValidNumberInputValue(e?.target?.value)) {
          props.onChange?.(e);
        }
      } else {
        return props.onChange?.(e);
      }
    };

    return (
      <input
        {...props}
        onChange={handleChange}
        value={props.value === null ? undefined : props.value}
        className={classNames(
          className,
          'form-control',
          invalid && 'is-invalid',
        )}
        ref={refsToCallbackRef([localRef, ref])}
      />
    );
  },
);

function isValidNumberInputValue(input: string | Nil) {
  if (!input) {
    return true;
  }

  return !input.match(/[^0-9.,]/g);
}

export const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  Omit<
    React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    'value'
  > & { value?: string | Nil; invalid?: boolean; withTooltip?: boolean }
>(({ invalid, className, withTooltip, ...props }, ref) => {
  const el = (
    <textarea
      {...props}
      value={props.value || undefined}
      className={classNames(className, 'form-control', invalid && 'is-invalid')}
      ref={ref}
    />
  );

  return el;
});
