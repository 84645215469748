import icons, { TIconName } from './icons';

import classNames from 'classnames/bind';
import styles from './Icon.scss';

const cx = classNames.bind(styles);
const IconNotFound = () => null;

const Icon = ({
  className,
  icon,
  ...props
}: {
  className?: string;
  icon: TIconName;
  onClick?: () => void;
  background?: any;
  size?: number;
}) => {
  if (!icons[icon]) {
    throw new Error(`Icon not found: ${icon}`);
  }

  const IconComponent = icons[icon] || IconNotFound;
  return (
    <IconComponent
      {...props}
      style={{ fontSize: props.size }}
      className={cx(className, 'icon')}
    />
  );
};

export default Icon;
