import { FiltryISortowanie } from '../lib/Filtry';
import { useQueryParam } from './useQueryParams';

export const LIST_PARAMS_QUERY_KEY = 'FiltryISortowanie';

export function useUrlFiltryISortowanie(
  defaultFiltryISortowanie: FiltryISortowanie = {},
  config: {
    queryKey?: string;
  } = {},
) {
  const resolvedQueryKey = config.queryKey || LIST_PARAMS_QUERY_KEY;

  const [FiltryISortowanieParam, setFiltryISortowanieParam] = useQueryParam(
    resolvedQueryKey,
    {
      defaultValue: JSON.stringify(defaultFiltryISortowanie),
    },
  );

  const filtryISortowanie: FiltryISortowanie = parseFiltryISortowanieFromString(
    FiltryISortowanieParam,
  );
  const setFiltryISortowanie = (newFiltryISortowanie: FiltryISortowanie) => {
    setFiltryISortowanieParam(JSON.stringify(newFiltryISortowanie));
  };

  return {
    filtryISortowanie,
    setFiltryISortowanie,
  };
}

export function parseFiltryISortowanieFromString(
  input: undefined | string,
): FiltryISortowanie {
  try {
    if (!input) {
      return {};
    }
    return JSON.parse(input);
  } catch {
    return {};
  }
}
