import classNames from 'classnames';
import React from 'react';

const checkIsEmpty = (children: React.ReactElement | React.ReactNode) => {
  const arr = React.Children.toArray(children);
  return arr.length === 0;
};

const LabeledInfo = ({
  className,
  label,
  children = null,
  placeholder = '---',
}: {
  className?: string;
  label?: string;
  children?: React.ReactElement | React.ReactNode;
  placeholder?: string;
}) => {
  const isEmpty = checkIsEmpty(children);

  return (
    <div
      className={classNames(className, 'border-bottom')}
      data-test={`labeled-info-row-${label}`}
    >
      <div className="row">
        <div className="col-12 col-lg-7 py-1 text-end">
          <strong data-test="labeled-info-label" data-test-label={label}>
            {label}:
          </strong>
        </div>
        <div className="col-12 col-lg-5 py-1" data-test="labeled-info-value">
          {isEmpty ? placeholder : children}
        </div>
      </div>
    </div>
  );
};

export default LabeledInfo;
