import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import { PageTitleContextProvider } from '../components/PageTitleContext';
import { RouterProvider } from '../router/RouterProvider';
import { ReactQueryProvider } from './react-query';

function App() {
  return (
    <>
      <PageTitleContextProvider>
        <ReactQueryProvider>
          <RouterProvider />
        </ReactQueryProvider>
      </PageTitleContextProvider>

      <ToastContainer />
    </>
  );
}

export default App;
