import React, { CSSProperties } from 'react';
import { Nil, ReactChild } from '../../../helpers/types';

import classNames from 'classnames/bind';
import { FormLabel } from 'react-bootstrap';
import styles from './FieldContainer.scss';

const cx = classNames.bind(styles);

export interface FieldContainerProps {
  children?: React.ReactNode;
  label?: string | React.ReactElement;
  errors?: ReactChild | Nil;
  required?: boolean | Nil;
  className?: string;
  emptyLabelPadding?: boolean;
  warning?: ReactChild | Nil;
  style?: CSSProperties;
  helperText?: ReactChild | Nil;
}

export function FieldLabel({
  label,
  required,
  className,
  style,
}: {
  label: string | React.ReactElement;
  required?: boolean;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <FormLabel style={style} className={className}>
      {label}
      {required && <FieldRequiredStar />}
    </FormLabel>
  );
}

export function FieldRequiredStar() {
  return <sup className="text-danger font-weight-bold">*</sup>;
}

export function FieldContainer({
  children,
  label,
  errors,
  required,
  className,
  emptyLabelPadding,
  warning,
  style,
  helperText,
}: FieldContainerProps) {
  const resolvedErrors = !Array.isArray(errors) ? [errors] : errors;

  return (
    <div
      className={cx('d-flex flex-column justify-content-end', className)}
      style={style}
    >
      {label && <FieldLabel label={label} required={Boolean(required)} />}
      {!label && emptyLabelPadding && <FormLabel>{' '}</FormLabel>}
      {children}
      <span
        className="text-danger"
        style={{ minHeight: '1.4rem', fontSize: 'small' }}
      >
        {resolvedErrors
          ?.filter((e) => !!e)
          ?.map((error, i) => <p key={i}>{error}</p>)}

        {warning && (
          <>
            <span className="text-warning">{warning}</span>
          </>
        )}

        {helperText && (
          <>
            <span>{helperText}</span>
          </>
        )}
      </span>
    </div>
  );
}
