import { Controller, useForm } from 'react-hook-form';
import {
  Input,
  InputAdornment,
  InputTextAdornment,
} from '../../components/common/inputs/Input';
import { queryClient, queryKeys } from '../../entrypoint/react-query';
import { DaneLogowania, zaloguj } from '../../lib/auth';

import { useMutation } from 'react-query';
import { Button } from '../../components/common/Button';
import { FieldContainer } from '../../components/common/FieldContainer';
import { validationMessages } from '../../helpers/validation';

export function LoginForm() {
  const { control, handleSubmit } = useForm<DaneLogowania>({});

  const zalogujMutation = useMutation(zaloguj, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.daneSesji);
    },
  });

  const onSubmit = handleSubmit((result) => zalogujMutation.mutate(result));

  return (
    <form onSubmit={onSubmit}>
      <Controller
        control={control}
        name="login"
        rules={{ required: validationMessages.required }}
        render={({ field, fieldState }) => (
          <FieldContainer
            label="Login"
            required
            errors={fieldState.error?.message}
          >
            <InputAdornment
              endAdornment={
                <InputTextAdornment style={{ width: '200px' }}>
                  @episkopat.pl
                </InputTextAdornment>
              }
            >
              <Input
                {...field}
                invalid={fieldState.invalid}
                value={getValueWithoutDomain(field.value || '')}
                onChange={(e) =>
                  field.onChange(getValueWithDomain(e.target.value))
                }
                autoComplete="username"
              />
            </InputAdornment>
          </FieldContainer>
        )}
      />

      <Controller
        control={control}
        name="haslo"
        rules={{ required: validationMessages.required }}
        render={({ field, fieldState }) => (
          <FieldContainer
            label="Hasło"
            required
            errors={fieldState.error?.message}
          >
            <Input
              {...field}
              invalid={fieldState.invalid}
              autoComplete="password"
              type="password"
            />
          </FieldContainer>
        )}
      />

      <Button type="submit" isLoading={zalogujMutation.isLoading}>
        Zaloguj
      </Button>
    </form>
  );
}

function getValueWithDomain(val: string) {
  return `${val || ''}@episkopat.pl`;
}

function getValueWithoutDomain(val: string) {
  return (val || '').substring(
    0,
    (val?.length ?? 0) - (('episkopat.pl'?.length ?? 0) + 1),
  );
}
