import { Nil } from '../../../helpers/types';
import { Skeleton } from '../Skeleton';

export function ItemCount({
  count,
  loadable,
}: {
  count: number | Nil;
  loadable?: boolean;
}) {
  return (
    <div className="d-flex justify-content-end p-1 text-secondary">
      {typeof count === 'number'
        ? conjugatePolishCountPozycja(count)
        : loadable && <Skeleton height={20} width={150} />}
    </div>
  );
}

function conjugatePolishCountPozycja(count: number) {
  if (count === 0) {
    return '0 pozycji';
  } else if (count === 1) {
    return '1 pozycja';
  } else if (count >= 5 && count <= 21) {
    return `${count} pozycji`;
  } else {
    const lastDigit = count % 10;

    if (lastDigit >= 2 && lastDigit <= 4) {
      return `${count} pozycje`;
    } else {
      return `${count} pozycji`;
    }
  }
}
