import { Button } from '../Button';

export function FetchError({
  onRetry,
  isLoading,
  error,
}: {
  onRetry: () => void;
  error?: string;
  isLoading?: boolean;
}) {
  return (
    <div className="px-3 pt-3 d-flex flex-column align-items-center gap-3">
      <h2>Podczas wczytywania danych wystąpił błąd.</h2>
      {error && <span className="color-danger">{error}</span>}
      <Button onClick={onRetry} isLoading={isLoading} disabled={isLoading}>
        Spróbuj ponownie
      </Button>
    </div>
  );
}
