import { useLocation, useNavigate } from 'react-router';

import React from 'react';
import { getQueryParams } from '../helpers/queryParams';

export function useQueryParams<T>(): T {
  const location = useLocation();

  const params = React.useMemo(() => getQueryParams<T>(), [location.search]);

  return params;
}

export function useQueryParam<TDefaultValue = string>(
  paramName: string,
  config: { defaultValue: TDefaultValue },
): [TDefaultValue, (newVal: TDefaultValue) => void];

export function useQueryParam<TDefaultValue = string>(
  paramName: string,
  config?: { defaultValue: TDefaultValue },
): [TDefaultValue | undefined, (newVal: TDefaultValue) => void];

export function useQueryParam<TDefaultValue = string>(
  paramName: string,
  config: { defaultValue?: TDefaultValue } = {},
): [TDefaultValue | undefined, (newVal: TDefaultValue) => void] {
  const paramValueAsString = useQueryParams<{ [key: string]: string }>()[
    paramName
  ];
  const paramValue = safeParseJson(paramValueAsString);

  const history = useNavigate();

  const setParamValue = (newValue: TDefaultValue | undefined) => {
    const newSearch = new URLSearchParams(window.location.search);

    newSearch.set(paramName, JSON.stringify(newValue));
    history(
      {
        pathname: window.location.pathname,
        search: newSearch.toString(),
      },
      { replace: true },
    );
  };

  React.useEffect(() => {
    if (!paramValue && config.defaultValue) {
      setParamValue(config.defaultValue);
    }
  }, [paramValue, config.defaultValue]);

  return [
    (paramValue || config.defaultValue) as TDefaultValue | undefined,
    setParamValue,
  ];
}

function safeParseJson(val: any) {
  try {
    return JSON.parse(val);
  } catch {
    return undefined;
  }
}
