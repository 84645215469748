import { Nil, ReactChild } from '../../../helpers/types';

import classNames from 'classnames/bind';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { ThemeName } from '../../../helpers/bootstrap';
import { getIn } from '../../../helpers/utils';
import Icon from '../Icon';
import { TIconName } from '../Icon/icons';
import ProfilePicture from '../ProfilePicture';
import styles from './ListPageItem.scss';

const cx = classNames.bind(styles);

type Children =
  | string
  | React.ReactNode
  | React.ReactElement
  | React.ReactElement[]
  | null;

export type ListPageItemProps = {
  children?: Children;
  className?: string;
  label?: ReactChild | Nil;
  rootRef?: React.Ref<HTMLDivElement>;
  labelClassName?: string;
  badge?: ReactChild;
  bgColor?: ListPageItemColor | Nil;
} & Omit<React.HTMLProps<HTMLDivElement>, 'label'>;

const ListPageItem = ({
  children,
  className,
  label,
  labelClassName,
  rootRef,
  badge,
  bgColor,
  ...props
}: ListPageItemProps) => {
  const hasTopBar = Boolean(label || badge);

  return (
    <div
      {...props}
      className={cx(
        className,
        'list-page-item',
        'px-2',
        'px-md-0',
        hasTopBar && 'pt-3',
      )}
      style={{
        background:
          typeof bgColor === 'number'
            ? getIn(
                {
                  [ListPageItemColor.UncertainGray]: '#78838e',
                  [ListPageItemColor.HappyGreen]: ' #5dd879',
                  [ListPageItemColor.FullGreen]: ' #2b6100',
                  [ListPageItemColor.Error]: '#eb8c95',
                  [ListPageItemColor.Orange]: '#ffaa79',
                  [ListPageItemColor.NotHandledBlue]: '#66b0ff',
                  [ListPageItemColor.PendingGreen]: 'rgba(23, 162, 184, 0.45)',
                },
                bgColor,
              )
            : cx('list-page-item-default-bg'),
      }}
      ref={rootRef}
    >
      {hasTopBar && (
        <>
          <div
            className={cx('d-flex gap-3', !label && 'pl-3')}
            style={{ position: 'absolute', top: 0, left: 0 }}
          >
            {label && (
              <div
                className={cx(
                  labelClassName,
                  'text-white px-3 d-flex align-items-center',
                )}
                style={{
                  borderBottomRightRadius: '10px',
                  fontSize: '0.75rem',
                  backgroundColor: `rgba(0, 0, 0, 0.5)`,
                }}
              >
                {label}
              </div>
            )}

            {badge && <div style={{ padding: '2px 0' }}>{badge}</div>}
          </div>

          <div />
        </>
      )}
      {children}
    </div>
  );
};

ListPageItem.IndexCol = ({
  index,
  className,
  ...props
}: {
  index: number;
  className?: string;
} & React.HTMLProps<HTMLDivElement>) => (
  <div {...props} className={cx(className, 'itemCol', 'indexCol')}>
    {index}.
  </div>
);

export type ListPageItemPhotoColProps = {
  icon?: TIconName;
  deadRibbon?: boolean;
  className?: string;
  isLoading?: boolean;
  src?: string | Nil;
  srcSet?: string | Nil;
} & Omit<React.HTMLProps<HTMLDivElement>, 'src'>;

ListPageItem.PhotoCol = ({
  src,
  srcSet,
  icon,
  deadRibbon,
  className,
  isLoading,
  ...props
}: ListPageItemPhotoColProps) => {
  return (
    <div {...props} className={cx(className, 'itemCol', 'photoCol')}>
      <ProfilePicture
        src={src}
        srcSet={srcSet}
        icon={icon}
        deadRibbon={deadRibbon}
        isLoading={isLoading}
        className={cx('photo')}
      />
    </div>
  );
};

ListPageItem.DetailsCol = ({
  children,
  className,
  ...props
}: {
  children?: Children;
  className?: string;
} & React.HTMLProps<HTMLDivElement>) => (
  <div {...props} className={cx(className, 'itemCol', 'detailsCol')}>
    {children}
  </div>
);

ListPageItem.DetailsRow = ({
  children,
  ...props
}: {
  children?: Children;
} & React.HTMLProps<HTMLDivElement>) => <div {...props}>{children}</div>;

export interface ListPageItemDetailProps
  extends Omit<React.HTMLProps<HTMLSpanElement>, 'label' | 'value'> {
  label?: Children | Nil;
  value?: Children | Nil;
  className?: string;
}

ListPageItem.Detail = ({
  label,
  value,
  className,
  ...props
}: ListPageItemDetailProps) => (
  <span {...props} className={cx(className, 'detail')}>
    {!!label && (
      <>
        <strong className={cx('detail-label')}>{label}:&nbsp;</strong>
      </>
    )}
    {value || value === 0 ? value : 'Brak danych'}
  </span>
);

ListPageItem.PrimaryDetail = ({
  className,
  ...props
}: ListPageItemDetailProps) => (
  <ListPageItem.Detail className={cx(className, 'name')} {...props} />
);

ListPageItem.ButtonsCol = ({
  children,
  className,
  ...props
}: {
  children?: Children;
  className?: string;
} & React.HTMLProps<HTMLDivElement>) => (
  <div {...props} className={cx(className, 'itemCol', 'buttonsCol')}>
    {children}
  </div>
);

export function ListPageItemBadge({
  label,
  theme,
  icon,
}: {
  icon?: TIconName;
  label: string;
  theme?: ThemeName;
}) {
  return (
    <Badge
      style={{ backgroundColor: `rgba(0, 0, 0, 0.5)` }}
      className="w-fit-content d-flex px-1 py-1 align-items-center gap-2 text-white"
    >
      {icon && <Icon icon={icon} />} {label}
    </Badge>
  );
}

export enum ListPageItemColor {
  UncertainGray = 1,
  HappyGreen = 2,
  FullGreen = 3,
  Orange = 4,
  Error = 5,
  NotHandledBlue = 6,
  PendingGreen = 7,
}

export default ListPageItem;
