import React from 'react';
import { Path } from 'react-hook-form';

export type $TSFixMe = any;

export type ObjectPaths<T> = Path<T>;

export function typedObjKeys<Obj extends object>(obj: Obj): (keyof Obj)[] {
  return Object.keys(obj) as unknown as (keyof Obj)[];
}

export type WithAsProp<Val, TAs extends React.ElementType = any> = Omit<
  Val,
  'as'
> & {
  as?: TAs;
} & Omit<React.ComponentPropsWithoutRef<TAs>, keyof Val>;

export type Nil = undefined | null;

//https://medium.com/@KevinBGreene/typescript-modeling-required-fields-with-mapped-types-f7bf17688786
export type RequireOne<T, K extends keyof T> = {
  [X in Exclude<keyof T, K>]?: T[X];
} & {
  [P in K]-?: T[P];
};

// https://stackoverflow.com/questions/61132262/typescript-deep-partial
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type NilPartial<T> = T extends object
  ? {
      [P in keyof T]?: T[P] | Nil;
    }
  : T;

export type RequiredProperties<T, P extends keyof T> = Omit<T, P> & {
  [key in P]: NonNullable<T[P]>;
};

export type ReactChild =
  | React.ReactElement
  | React.ReactElement[]
  | React.ReactNode
  | React.ReactNode[]
  | boolean
  | false
  | true
  | null
  | string
  | number;

export interface QueryParams {
  [key: string | number]: string | number | null | undefined;
}
