export const Url = {
  home: '/',
  login: '/',
  katecheza: {
    parafialneAnkietyKatechetyczne: {
      list: '/katecheza/ankiety-parafialne',
      wyniki: '/katecheza/ankiety-parafialne-wyniki',
      single: (id: string) => `/katecheza/ankiety-parafialne/${id}`,
    },
  },
  diecezje: {
    single: (id: string) => `/diecezja/${id}`,
    list: '/diecezje',
  },
  finanse: {
    index: '/finanse/index',
  },
};
