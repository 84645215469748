import { ReactChild } from '../../helpers/types';
import { PageTitle } from '../PageTitleContext';
import { Content } from './Content';
import { Sidebar } from './Sidebar';

export function Layout({
  children,
  pageTitle,
}: {
  children?: ReactChild;
  pageTitle?: string;
}) {
  return (
    <>
      <div className="d-flex">
        <Sidebar />

        <Content children={children} />
      </div>

      {pageTitle && <PageTitle title={pageTitle} />}
    </>
  );
}
