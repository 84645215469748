import { QueryClient, QueryClientProvider } from 'react-query';

import { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { FiltryISortowanie } from '../lib/Filtry';

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error) => {
        toast('Wystąpił błąd', { type: 'error' });
      },
    },
  },
});

export function ReactQueryProvider({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}

export const queryKeys = {
  daneSesji: ['dane-sesji'],
  katecheza: {
    ankietyParafialneKatechetyczne: {
      list: (filtry: FiltryISortowanie) => ['katecheza', 'ankiety', filtry],
      single: (id: string) => ['katecheza', 'ankiety', id],
    },
  },
  diecezje: {
    list: (filtry: FiltryISortowanie) => ['katecheza', 'diecezje', filtry],
    single: (id: string) => ['diecezje', id],
  },
};
