export async function sleep(time: number) {
  return await new Promise((r) => setTimeout(r, time));
}

export interface IdINazwa {
  id: string;
  nazwa: string;
}

export enum PodmiotTypId {
  Osoba = 1,
  Parafia = 2,
  Diecezja = 3,
  Kontrahent = 4,
}
