import React from 'react';
import { ReactChild } from '../helpers/types';

interface IPageTitleContext {
  pageTitle: string;
  setPageTitle: (newPagTitle: string) => void;
}

export const PageTitleContext = React.createContext<IPageTitleContext>({
  pageTitle: '',
  setPageTitle: () => null,
});

export function PageTitleContextProvider({
  children,
}: {
  children: ReactChild;
}) {
  const [pageTitle, setPageTitle] = React.useState('');

  return (
    <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
      {children}
    </PageTitleContext.Provider>
  );
}

export function PageTitle({ title }: { title: string }) {
  React.useContext(PageTitleContext).setPageTitle(title);

  return null;
}
