import { Pageable } from './fetcher';
import { IdINazwa, sleep } from './misc';

export interface ParafialnaAnkietaKatechetycznaListItem {
  parafia: ParafialnaAnkietaKatechetycznaParafia;
  czyWypelnionaZaTenRok: boolean;
  id: string;
}

export interface ParafialnaAnkietaKatechetycznaParafia {
  id: string;
  nazwa: string;
  diecezja: IdINazwa;
  proboszcz: IdINazwa;
}

export async function getListaParafialnychAnkietKatechetycznych() {
  await sleep(1500);

  return {
    dane: Array(50)
      .fill(1)
      .map(() => ({
        id: Math.random().toString(),
        czyWypelnionaZaTenRok: Math.random() > 0.5,
        parafia: {
          id: Math.random().toString(),
          diecezja: {
            id: 'awdawd',
            nazwa:
              Math.random() > 0.5 ? 'Diecezja Radomska' : 'Diecezja Świdnicka',
          },
          nazwa:
            Math.random() > 0.2
              ? 'św. Stanisława Kostki'
              : Math.random() > 0.2
              ? 'św. Jana Pawła II'
              : 'św. Jana Nepomucena',
          proboszcz: {
            id: '328490834',
            nazwa:
              Math.random() > 0.5
                ? 'ks. Mariusz Szczęsny'
                : 'ks. kan. Jan Środa',
          },
        },
      })),
    liczbaStron: 100,
    liczbaWynikow: 10352,
    nastepnaStronaIndeks: 5,
  } satisfies Pageable<ParafialnaAnkietaKatechetycznaListItem>;
}

export interface ParafialnaAnkietaKatechetyczna {
  id: string;
  parafia: ParafialnaAnkietaKatechetycznaParafia;
  rok: number;
}

export async function getParafialnaAnkietaKatechetyczna(id: string) {
  await sleep(1500);
  return {
    id,
    parafia: {
      id: Math.random().toString(),
      diecezja: {
        id: 'awdawd',
        nazwa: Math.random() > 0.5 ? 'Diecezja Radomska' : 'Diecezja Świdnicka',
      },
      nazwa:
        Math.random() > 0.2
          ? 'św. Stanisława Kostki'
          : Math.random() > 0.2
          ? 'św. Jana Pawła II'
          : 'św. Jana Nepomucena',
      proboszcz: {
        id: '328490834',
        nazwa:
          Math.random() > 0.5 ? 'ks. Mariusz Szczęsny' : 'ks. kan. Jan Środa',
      },
    },
    rok: 2023,
  } satisfies ParafialnaAnkietaKatechetyczna;
}
