import {
  Button as ButtonBS,
  ButtonProps as TLibButtonProps,
} from 'react-bootstrap';
import Icon, { TIconName } from '../Icon';

import { Nil } from '../../../helpers/types';
import { Spinner } from '../Spinner';

export type ButtonProps = TLibButtonProps & {
  isLoading?: boolean | Nil;
  icon?: TIconName;
};

export function Button(props: ButtonProps) {
  const isLoading = Boolean(props.isLoading);

  return (
    <ButtonBS {...props} disabled={props.disabled || isLoading}>
      <span
        className="d-flex align-items-center gap-2"
        style={{
          position: 'relative',
        }}
      >
        {props.icon && <Icon icon={props.icon} />}
        {isLoading && (
          <span style={{ position: 'absolute', left: '30%' }}>
            <Spinner size="sm" />
          </span>
        )}
        <span
          style={{
            filter: isLoading ? 'blur(2px)' : '',
          }}
        >
          {props.children}
        </span>
      </span>
    </ButtonBS>
  );
}
