import _isNil from 'lodash/isNil';
import { _dayjs } from './dayjs';
import { Nil } from './types';

export const interleave = <TItem, TInserted>(
  arr: TItem[],
  thing: TInserted,
): (TItem | TInserted)[] => [...arr.flatMap((n) => [n, thing])].slice(0, -1);

export const interleaveString = (
  arr: (string | Nil | boolean)[],
  thing: string,
): string =>
  interleave(
    truthyIn(arr).filter((x) => typeof x === 'string' && x.trim().length > 0),
    thing,
  ).join('');

export function safeJsonParse(input: any) {
  try {
    return JSON.parse(input);
  } catch {
    return undefined;
  }
}
export function arrayMoveImmutable<T>(
  array: T[],
  fromIndex: number,
  toIndex: number,
): T[] {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}

function arrayMoveMutable<T>(array: T[], fromIndex: number, toIndex: number) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item!);
  }
}

export function arrayInsert<T>(arr: T[], item: T, index: number): T[] {
  const newArr = [...arr];
  newArr.splice(Math.min(newArr.length, index), 0, item);
  return newArr;
}

export function getIn<T extends object>(obj: T, val: keyof T) {
  return obj[val];
}

//https://stackoverflow.com/questions/3410464/how-to-find-indices-of-all-occurrences-of-one-string-in-another-in-javascript
export function getAllIndexes(source: string, find: string) {
  const result: number[] = [];
  var i = 0;
  while (i < source.length) {
    if (source.substring(i, i + find.length) === find) result.push(i++);
    else i++;
  }
  return result;
}

export function truthyIn<T>(items: (T | Nil)[]): NonNullable<T>[] {
  return items.filter((x) => !_isNil(x)) as NonNullable<T>[];
}

export function ellipsis(input: string, maxLen: number) {
  if (input.length <= maxLen) {
    return input;
  } else {
    return `${input.substring(0, maxLen)}...`;
  }
}

export function mimeToExtension(mime: string) {
  return {
    'text/css': 'css',
    'text/html': 'html',
    'image/gif': 'gif',
    'text/xml': 'xml',
    'application/x-javascript': 'js',
    'image/jpeg': 'jpeg',
    'application/rss+xml': 'rss',
    'application/atom+xml': 'atom',
    'text/plain': 'txt',
    'text/mathml': 'mml',
    'text/vnd.wap.wml': 'wml',
    'text/vnd.sun.j2me.app-descriptor': 'jad',
    'image/png': 'png',
    'text/x-component': 'htc',
    'image/vnd.wap.wbmp': 'wbmp',
    'image/tiff': 'tiff',
    'image/x-jng': 'jng',
    'image/x-icon': 'ico',
    'image/svg+xml': 'svg',
    'image/x-ms-bmp': 'bmp',
    'image/webp': 'webp',
    'application/msword': 'doc',
    'application/mac-binhex40': 'hqx',
    'application/pdf': 'pdf',
    'application/vnd.ms-excel': 'xls',
    'application/rtf': 'rtf',
    'application/vnd.wap.wmlc': 'wmlc',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.google-earth.kmz': 'kmz',
    'application/vnd.google-earth.kml+xml': 'kml',
    'application/x-cocoa': 'cco',
    'application/x-7z-compressed': '7z',
    'application/x-java-jnlp-file': 'jnlp',
    'application/x-java-archive-diff': 'jardiff',
    'application/x-perl': 'pl',
    'application/x-makeself': 'run',
    'application/x-rar-compressed': 'rar',
    'application/x-pilot': 'prc',
    'application/x-sea': 'sea',
    'application/x-redhat-package-manager': 'rpm',
    'application/x-stuffit': 'sit',
    'application/x-shockwave-flash': 'swf',
    'application/x-x509-ca-cert': 'der',
    'application/x-tcl': 'tcl',
    'application/xhtml+xml': 'xhtml',
    'application/x-xpinstall': 'xpi',
    'application/octet-stream': 'bin',
    'application/zip': 'zip',
    'audio/midi': 'mid',
    'audio/ogg': 'ogg',
    'audio/mpeg': 'mp3',
    'video/3gpp': '3gpp',
    'audio/x-realaudio': 'ra',
    'video/quicktime': 'mov',
    'video/mpeg': 'mpeg',
    'video/x-mng': 'mng',
    'video/x-flv': 'flv',
    'video/x-ms-wmv': 'wmv',
    'video/x-ms-asf': 'asx',
    'video/mp4': 'm4v',
    'video/x-msvideo': 'avi',
  }[mime];
}

export function dataUriToBase64(str: string) {
  return str.split('base64,').slice(1).join('');
}

export function isTimespanNow(start: string | Nil, end: string | Nil) {
  return Boolean(
    (!start || _dayjs(start).isBefore(_dayjs())) &&
      (!end || _dayjs(end).isAfter(_dayjs())),
  );
}

export function arrayExceptIndex<T>(arr: T[], index: number): T[] {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}
