import {
  FeatureIconName,
  IconName,
  TIconName,
} from '../../../components/common/Icon';

import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { Layout } from '../../../components/Layout';
import Icon from '../../../components/common/Icon';
import styles from './FinanseIndexView.scss';

const cx = classnames.bind(styles);

export function FinanseIndexView() {
  return (
    <Layout pageTitle="Finanse">
      <div className="d-flex gap-3 row">
        <TileLink label="Kasy/Banki" icon={IconName.Money} />

        <TileLink
          label="Rejestr dokumentów"
          icon={FeatureIconName.FinanseDokumenty}
        />

        <TileLink label="Naliczenia" icon={IconName.IncomeTax} />

        <TileLink label="Zobowiązania" icon={FeatureIconName.Zobowiazania} />

        <TileLink label="Należności" icon={FeatureIconName.Naleznosci} />

        <TileLink label="Polecenia księgowania" icon={IconName.WebCode} />

        <TileLink label="Rozliczenia" icon={IconName.Accounting} />

        <TileLink label="Wynagrodzenia" icon={IconName.Investor} />

        <TileLink
          label="Kategorie rozliczeniowe"
          icon={IconName.FoldersHierarchy}
        />

        <TileLink label="Kategorie parafialne" icon={IconName.GalleryDetail} />

        <TileLink label="Zestawienia" icon={IconName.Donut} />

        <TileLink label="Zaliczki" icon={FeatureIconName.Zaliczki} />
      </div>
    </Layout>
  );
}

function TileLink({
  label,
  icon,
  href,
  disabled,
}: {
  label: string;
  icon?: TIconName;
  href?: string;
  disabled?: boolean;
}) {
  return (
    <Link
      to={href || '#'}
      aria-disabled={disabled}
      className={cx(
        'col-5 col-lg-3 col-xl-2 d-flex justify-content-center',
        'linkTile',
        disabled && 'disabled',
      )}
      style={{
        textDecoration: 'none',
        display: 'block',
        opacity: disabled ? '0.2' : '',
      }}
    >
      {icon && <Icon icon={icon} size={50} />}
      {label}
    </Link>
  );
}
