import 'dayjs/locale/pl';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);
dayjs.locale('pl');

export const weekDays = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

/**
 * Returns array with dates of start and end of year
 * @param {number} year Year to get bounds for
 * @param {string} format Format in which dates should be returned
 */
export const getYearBounds = (year: number, format = 'YYYY-MM-DD') => {
  const yearObj = dayjs().year(year);
  return [
    yearObj.startOf('year').format(format),
    yearObj.endOf('year').format(format),
  ];
};

export { dayjs as _dayjs };
