import { Chart } from 'react-charts';
import { Layout } from '../../../components/Layout';

export function AnkietyKatechetyczneWynikiView() {
  return (
    <Layout pageTitle="Wyniki ankiet katechetycznych">
      <h3>Procent uczniów uczęszczających na religię</h3>
      <div style={{ width: 500, height: 300 }}>
        <Chart
          options={{
            data: [
              {
                data: [
                  {
                    nazwa: 'Diecezja Radomska',
                    ile: 84,
                  },
                  {
                    nazwa: 'Diecezja Świdnicka',
                    ile: 87,
                  },
                  {
                    nazwa: 'Diecezja Pelplińska',
                    ile: 82,
                  },
                  {
                    nazwa: 'Diecezja Drohiczyńska',
                    ile: 92,
                  },
                  {
                    nazwa: 'Archidiecezja Gdańska',
                    ile: 85,
                  },
                  {
                    nazwa: 'Diecezja Łowicka',
                    ile: 95,
                  },
                  {
                    nazwa: 'Diecezja Warszawsko-Praska',
                    ile: 89,
                  },
                ],
                label: 'Wynik',
              },
            ],
            primaryAxis: {
              getValue: (x) => x.nazwa,
            },
            secondaryAxes: [
              {
                getValue: (x) => x.ile,
              },
            ],
          }}
        />
      </div>

      <h3>Średnia liczba placówek katolickich w parafii</h3>
      <div style={{ width: 500, height: 300 }}>
        <Chart
          options={{
            data: [
              {
                data: [
                  {
                    nazwa: 'Diecezja Radomska',
                    ile: 8.4,
                  },
                  {
                    nazwa: 'Diecezja Świdnicka',
                    ile: 9.0,
                  },
                  {
                    nazwa: 'Diecezja Pelplińska',
                    ile: 9.5,
                  },
                  {
                    nazwa: 'Diecezja Drohiczyńska',
                    ile: 9.2,
                  },
                  {
                    nazwa: 'Archidiecezja Gdańska',
                    ile: 8.5,
                  },
                  {
                    nazwa: 'Diecezja Łowicka',
                    ile: 9.5,
                  },
                  {
                    nazwa: 'Diecezja Warszawsko-Praska',
                    ile: 8.5,
                  },
                ],
                label: 'Wynik',
              },
            ],
            primaryAxis: {
              getValue: (x) => x.nazwa,
            },
            secondaryAxes: [
              {
                getValue: (x) => x.ile,
              },
            ],
          }}
        />
      </div>
    </Layout>
  );
}
