import Icon, {
  FeatureIconName,
  IconName,
  TIconName,
} from '../../components/common/Icon';

import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout';
import { Url } from '../../router/urls';
import styles from './HomeView.scss';

const cx = classNames.bind(styles);

export function HomeView() {
  return (
    <Layout pageTitle="Strona główna">
      <h2 className="text-primary">Szczęść Boże!</h2>

      <div className="row d-flex gap-3">
        <TileLink
          href={Url.diecezje.list}
          icon={IconName.LandPiece}
          label="Diecezje"
        />

        <TileLink
          href={Url.home}
          icon={FeatureIconName.Parafia}
          label="Parafie"
          disabled
        />

        <TileLink
          href={Url.home}
          icon={FeatureIconName.Ksieza}
          label="Księża"
          disabled
        />

        <TileLink
          href={Url.home}
          icon={FeatureIconName.Biskupi}
          disabled
          label="Biskupi"
        />

        <TileLink
          href={Url.home}
          icon={IconName.MailOpen}
          label="Dekrety"
          disabled
        />

        <TileLink
          href={Url.home}
          icon={IconName.CalendarEvent}
          label="Wydarzenia"
          disabled
        />

        <TileLink
          href={Url.home}
          icon={IconName.MailUnread}
          label="Komunikaty"
          disabled
        />

        <TileLink
          href={Url.home}
          icon={IconName.DocumentSharing}
          label="Wzory dokumentów"
          disabled
        />

        <TileLink href={Url.home} label="Rady episkopalne" disabled />

        <TileLink
          href={Url.home}
          label="Legitymacje"
          icon={FeatureIconName.Legitymacje}
          disabled
        />

        <TileLink
          href={Url.finanse.index}
          label="Finanse"
          icon={FeatureIconName.Finanse}
        />

        <TileLink
          href={Url.home}
          label="Registratura"
          disabled
          icon={FeatureIconName.Registratura}
        />

        <TileLink
          href={Url.home}
          label="Nieruchomości"
          icon={IconName.Building}
          disabled
        />

        <TileLink
          href={Url.home}
          label="Ruchomości"
          icon={IconName.Folders}
          disabled
        />

        <TileLink href={Url.home} label="Placówki" disabled />

        <TileLink
          href={Url.home}
          label="Osoby"
          disabled
          icon={FeatureIconName.Osoby}
        />

        <TileLink
          href={Url.home}
          label="Kontrahenci"
          disabled
          icon={IconName.FinancialAgreement}
        />

        <h4 className="text-primary col-12">Wydział katechetyczny</h4>

        <TileLink
          href={Url.katecheza.parafialneAnkietyKatechetyczne.list}
          label="Parafialne ankiety katechetyczne"
          icon={IconName.FactCheck}
        />

        <TileLink
          href={Url.katecheza.parafialneAnkietyKatechetyczne.wyniki}
          label="Wyniki ankiet katechetycznych"
          icon={IconName.Chart}
        />

        <h4 className="text-primary col-12">Formularze</h4>

        <TileLink
          href={Url.home}
          label="Formularze parafialne"
          disabled
          icon={IconName.Church}
        />

        <h4 className="text-primary col-12">Zarządzanie systemem</h4>

        <TileLink
          href={Url.home}
          label="Użytkownicy"
          disabled
          icon={IconName.People}
        />
      </div>
    </Layout>
  );
}

function TileLink({
  label,
  icon,
  href,
  disabled,
}: {
  label: string;
  icon?: TIconName;
  href?: string;
  disabled?: boolean;
}) {
  return (
    <Link
      to={href || '#'}
      aria-disabled={disabled}
      className={cx(
        'col-5 col-lg-3 col-xl-2 d-flex justify-content-center',
        'linkTile',
        disabled && 'disabled',
      )}
      style={{
        textDecoration: 'none',
        display: 'block',
        opacity: disabled ? '0.2' : '',
      }}
    >
      {icon && <Icon icon={icon} size={50} />}
      {label}
    </Link>
  );
}
