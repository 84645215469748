import { Pageable } from './fetcher';
import { sleep } from './misc';

export interface IDiecezjaListItem {
  id: string;
  nazwa: string;
  liczbaParafii: number;
  liczbaKsiezy: number;
  email: string;
  telefon: string;
  adres: string;
}

export async function getListaDiecezji() {
  await sleep(1500);

  return {
    dane: Array(50)
      .fill(1)
      .map(() => ({
        id: Math.random().toString(),
        nazwa: Math.random() > 0.5 ? 'Diecezja Radomska' : 'Diecezja Łowicka',
        liczbaKsiezy: 500,
        liczbaParafii: 301,
        telefon: '+48 345 123 212',
        email: 'kuria@diecezja.radom.pl',
        adres: 'ul. Malczewskiego 12, 26-600 Radom',
      })),
    liczbaStron: 100,
    liczbaWynikow: 10352,
    nastepnaStronaIndeks: 5,
  } satisfies Pageable<IDiecezjaListItem>;
}

export interface Diecezja {
  id: string;
  nazwa: string;
  liczbaParafii: number;
  liczbaKsiezy: number;
  adres: string;
  email: string;
  telefon: string;
  stronaWWW: string;
  patronowie: string;
  godzinyUrzedowaniaKurii: string;
  nip: string;
  liczbaMieszkancow: number;
  liczbaWiernych: number;
}

export async function getDiecezja(id: string) {
  return {
    id,
    liczbaKsiezy: 500,
    liczbaParafii: 301,
    nazwa: 'Diecezja Radomska',
    adres: 'ul. Malczewskiego 12, 26-600 Radom',
    email: 'kuria@diecezja.radom.pl',
    godzinyUrzedowaniaKurii: '09:00 - 15:00',
    liczbaMieszkancow: 400000,
    liczbaWiernych: 390000,
    nip: '45325423432',
    patronowie: 'św. Kazimierz Jagiellończyk',
    stronaWWW: 'diecezja.radom.pl',
    telefon: '+48 345 123 212',
  } satisfies Diecezja;
}
