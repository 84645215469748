import { Nil } from '../helpers/types';
import { sleep } from './misc';

export interface DaneLogowania {
  login: string;
  haslo: string;
}

export async function zaloguj(dane: DaneLogowania) {
  await sleep(1500);
  authTokenStore.store('awdawdwad');
}

export type DaneSesji =
  | {
      czyZalogowano: false;
      uzytkownik?: Nil;
    }
  | {
      czyZalogowano: true;
      uzytkownik: DaneUzytkownika;
    };

export interface DaneUzytkownika {
  nazwa: string;
}

export async function getDaneSesji() {
  await sleep(1500);

  if (authTokenStore.get()) {
    return {
      czyZalogowano: true,
      uzytkownik: {
        nazwa: 'Indico Plus - administrator systemu',
      },
    } satisfies DaneSesji;
  } else {
    return {
      czyZalogowano: false,
    } satisfies DaneSesji;
  }
}

const TOKEN_LOCALSTORAGE_KEY = 'authtoken';

const authTokenStore = {
  store(token: string) {
    sessionStorage.setItem(TOKEN_LOCALSTORAGE_KEY, token);
  },
  get() {
    return sessionStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
  },
};

export async function wyloguj() {
  await sleep(2500);
  authTokenStore.store('');
}
