import { Nil } from '../helpers/types';

export interface Pageable<T> {
  liczbaWynikow: number;
  dane: T[];
  liczbaStron: number;
  nastepnaStronaIndeks: number | undefined;
}

export function pagesToItems<T>(pages: Pageable<T>[] | Nil): T[] {
  return pages?.flatMap((x) => x.dane) || [];
}
