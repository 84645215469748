import { createBrowserRouter, useParams } from 'react-router-dom';

import { HomeView } from '../views/HomeView';
import { LoginView } from '../views/LoginView/';
import { DiecezjaView } from '../views/diecezje/DiecezjaView';
import { DiecezjeView } from '../views/diecezje/DiecezjeView';
import { FinanseIndexView } from '../views/finanse/FinanseIndexView';
import { AnkietyKatechetyczneWynikiView } from '../views/katecheza/AnkietyKatechetyczneWynikiView';
import { KatechetycznaAnkietaParafialnaView } from '../views/katecheza/KatechetycznaAnkietaParafialnaView';
import { KatechetyczneAnkietyParafialneView } from '../views/katecheza/KatechetyczneAnkietyParafialneView/KatechetyczneAnkietyParafialneView';
import { Url } from './urls';

export const unauthorizedRouter = createBrowserRouter([
  {
    path: Url.home,
    element: <LoginView />,
  },
]);

export const ROUTE_PATH_ID_PARAM_FILLER = ':id';

export function useIdParam() {
  return useParams<{ id: string }>().id || '';
}

export const authorizedRouter = createBrowserRouter([
  {
    path: Url.home,
    element: <HomeView />,
  },
  {
    path: Url.katecheza.parafialneAnkietyKatechetyczne.list,
    element: <KatechetyczneAnkietyParafialneView />,
  },
  {
    path: Url.katecheza.parafialneAnkietyKatechetyczne.single(
      ROUTE_PATH_ID_PARAM_FILLER,
    ),
    element: <KatechetycznaAnkietaParafialnaView />,
  },
  {
    path: Url.katecheza.parafialneAnkietyKatechetyczne.wyniki,
    element: <AnkietyKatechetyczneWynikiView />,
  },
  {
    path: Url.diecezje.list,
    element: <DiecezjeView />,
  },
  {
    path: Url.diecezje.single(ROUTE_PATH_ID_PARAM_FILLER),
    element: <DiecezjaView />,
  },
  {
    path: Url.finanse.index,
    element: <FinanseIndexView />,
  },
]);
