import React from 'react';
import { Icon } from 'react-icons-kit';

const loadReactIconsKitIcon = (promise: () => Promise<any>) => {
  const ReactIconsKitIconLoader = (props: any) => {
    const [isMounted, setIsMounted] = React.useState(false);
    const [module, setModule] = React.useState(null);

    React.useEffect(() => {
      promise()
        .then((mod) => {
          setModule(mod);
        })
        .catch((e) => console.error(e));

      return () => {
        setIsMounted(false);
      };
    }, []);

    if (!module || !Object.values(module).length) {
      return null;
    }

    const icon = Object.values(module)[0];

    console.log(icon, module);

    return <Icon {...props} icon={icon} size="1em" />;
  };

  return ReactIconsKitIconLoader;
};

export default loadReactIconsKitIcon;
