import { queryClient, queryKeys } from '../../entrypoint/react-query';

import React from 'react';
import { useMutation } from 'react-query';
import { ReactChild } from '../../helpers/types';
import { wyloguj } from '../../lib/auth';
import { PageTitleContext } from '../PageTitleContext';
import { Button } from '../common/Button';
import { IconName } from '../common/Icon';

export function Content({ children }: { children: ReactChild }) {
  return (
    <div className="col-12 col-lg-9 col-xl-10">
      <TopBar />

      <main
        style={{ height: 'calc(100vh - 3.15rem)', overflowY: 'auto' }}
        className="px-4 py-3"
      >
        <div style={{ zIndex: '1', height: '100%' }}>{children}</div>

        <img
          src={'/logo-kep.svg'}
          style={{
            position: 'absolute',
            left: '45%',
            top: '25%',
            filter: 'opacity(10%)',
            zIndex: '-1',
          }}
        />
      </main>
    </div>
  );
}

function TopBar() {
  const wylogujMutation = useMutation(wyloguj, {
    onSuccess: async () =>
      await queryClient.invalidateQueries(queryKeys.daneSesji),
  });

  const pageTitleContext = React.useContext(PageTitleContext);

  return (
    <div
      style={{ background: '#01808f', width: '100%', height: '3.15rem' }}
      className="px-4 d-flex justify-content-between align-items-center shadow"
    >
      <h4 className="text-light pt-2">{pageTitleContext.pageTitle}</h4>

      <div>
        <Button
          icon={IconName.Power}
          variant="light"
          isLoading={wylogujMutation.isLoading}
          onClick={() => wylogujMutation.mutate()}
        >
          Wyloguj
        </Button>
      </div>
    </div>
  );
}
