import 'react-virtualized/styles.css';
import './VirtualList.scss';

import {
  ListChildComponentProps,
  VariableSizeList as WindowList,
} from 'react-window';
import { Nil, ReactChild } from '../../../helpers/types';

import _throttle from 'lodash/throttle';
import ResizeObserverComponent from 'rc-resize-observer';
import React from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import { refsToCallbackRef } from '../../../helpers/mergeRefs';
import { Skeleton } from '../Skeleton';

export interface VirtualListScrollRef {
  scrollToItem(index: number): void;
  scrollToBottom(): void;
}

interface BaseVirtualListProps {
  itemCount: number | Nil;
  noItemsRenderer?: Nil | (() => React.ReactElement);
  renderChild: (props: { index: number }) => ReactChild;
  renderHeader?: () => null | React.ReactElement;
  isLoading?: boolean;
  scrollRef?: React.Ref<VirtualListScrollRef | Nil>;
  onScrollToItem?: (itemIndex: number) => void;
  containerRef?: React.Ref<HTMLDivElement>;
}

interface StaticVirtualListProps {
  isInfinite?: Nil | false;
}

interface InfiniteVirtualListProps {
  isInfinite: true;
  loadMoreRows: () => Promise<any>;
}

export type VirtualListProps = BaseVirtualListProps &
  (StaticVirtualListProps | InfiniteVirtualListProps);

export function VirtualList(props: VirtualListProps) {
  const { renderChild, renderHeader = () => null, noItemsRenderer } = props;

  const itemCount = props.itemCount || 0;

  const rowCount = itemCount + 2;

  const hasNoItems = !props.isLoading && itemCount < 1;

  const loadMoreItems = props.isInfinite
    ? async () => {
        if (!props.isLoading) {
          return props.loadMoreRows();
        }
      }
    : async () => null;

  const listRef = React.useRef<WindowList | Nil>();

  const rowHeightsRef = React.useRef<{ [index: number]: number }>({});

  const throttledForceUpdate = React.useCallback(
    _throttle(() => listRef?.current?.forceUpdate(), 100),
    [listRef],
  );

  const getHeight = (index: number) => rowHeightsRef.current[index] || 200;

  const setHeight = (index: number, newHeight: number) => {
    if (newHeight === getHeight(index)) {
      return;
    }

    rowHeightsRef.current[index] = newHeight;

    listRef.current?.resetAfterIndex(index, false);
    throttledForceUpdate();
  };
  React.useEffect(() => {
    if (props.scrollRef) {
      const scrollToItemIndex = (targetItemIndex: number) => {
        listRef?.current?.scrollToItem(
          itemIndexToRowIndex(targetItemIndex),
          'start',
        );
      };

      refsToCallbackRef([props.scrollRef])({
        scrollToItem: (index: number) => {
          scrollToItemIndex(index);
          setTimeout(() => scrollToItemIndex(index), 500);
          setTimeout(() => scrollToItemIndex(index), 750);
        },
        scrollToBottom: () => {
          listRef.current?.scrollToItem(itemCount + 100);
        },
      });
    }
  }, [props.scrollRef, listRef]);

  return (
    <AutoSizer>
      {({ width, height }) => (
        <InfiniteLoader
          isRowLoaded={({ index }) => index < rowCount - 1}
          rowCount={rowCount + 1}
          loadMoreRows={loadMoreItems}
        >
          {({ onRowsRendered, registerChild: registerListRef }) => (
            <WindowList
              height={height}
              width={width}
              overscanCount={15}
              className="virtual-list"
              itemCount={rowCount}
              itemSize={getHeight}
              onItemsRendered={(params) => {
                props.onScrollToItem?.(
                  Math.max(0, params.visibleStartIndex - 1),
                );
                onRowsRendered({
                  startIndex: params.overscanStartIndex,
                  stopIndex: params.overscanStopIndex,
                });
              }}
              itemData={{
                renderChild,
                renderHeader,
                setRowHeight: setHeight,
                noItemsRenderer,
                hasNoItems,
                itemCount,
                rowCount,
                isLoading: props.isLoading,
              }}
              ref={refsToCallbackRef([registerListRef, listRef])}
              outerRef={props.containerRef}
            >
              {Row}
            </WindowList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}

type VirtualListRowData = Pick<
  VirtualListProps,
  'noItemsRenderer' | 'renderChild' | 'renderHeader' | 'itemCount'
> & {
  rowCount: number;
  hasNoItems?: boolean;
  isLoading?: boolean;
  setRowHeight: (index: number, newHeight: number) => void;
};

function rowIndexToItemIndex(rowIndex: number) {
  return rowIndex - 1;
}

function itemIndexToRowIndex(itemIndex: number) {
  return itemIndex + 1;
}

function Row({
  index,
  style,
  data,
}: ListChildComponentProps<VirtualListRowData>) {
  const {
    renderChild,
    renderHeader,
    setRowHeight,
    noItemsRenderer,
    rowCount,
    hasNoItems,
    isLoading,
  } = data;

  let content: ReactChild;
  let key: string | Nil;

  const isHeader = index === 0;
  const isFooter = index === rowCount - 1;
  const isItem = !isHeader && !isFooter;
  let isEmpty = false;

  if (isHeader) {
    content = renderHeader?.() || (
      <div style={{ height: '1px', width: '100%' }} />
    );
    key = 'header';
  } else if (isFooter && isLoading) {
    content = (
      <div className="py-3">
        <Skeleton lines={5} height={100} />
      </div>
    );
    key = 'loader';
  } else if (isFooter && hasNoItems) {
    content = noItemsRenderer ? noItemsRenderer() : <div>Brak wyników</div>;
    key = 'virtual-list-no-results';
  } else if (isItem) {
    content = renderChild?.({ index: rowIndexToItemIndex(index) });
    isEmpty = !content;
    key = undefined;
  } else {
    content = <div />;
    key = 'virtual-list-empty-component';
    isEmpty = true;
  }

  React.useEffect(() => {
    if (isEmpty) {
      setRowHeight(index, 1);
    }
  }, [isEmpty]);

  return (
    <div
      style={{
        ...style,
        paddingRight: 10,
      }}
      key={key}
    >
      <ThrottledResizeObserver
        onResize={(newHeight) => {
          if (isEmpty) {
            setRowHeight(index, 1);
          } else {
            setRowHeight(index, newHeight);
          }
        }}
        timeout={isHeader ? 25 : 500}
      >
        {content}
      </ThrottledResizeObserver>
    </div>
  );
}

function ThrottledResizeObserver({
  children,
  onResize,
  timeout,
}: {
  onResize: (newHeight: number) => void;
  timeout: number;
  children: any;
}) {
  const handleResize = React.useCallback(_throttle(onResize, timeout), [
    onResize,
    timeout,
  ]);

  return (
    <ResizeObserverComponent
      onResize={(newSize, el) => {
        let newHeight = Math.max(newSize.height, 1);
        const styles = el && window.getComputedStyle(el);
        if (styles) {
          newHeight +=
            parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);
        }
        handleResize(newHeight);
      }}
    >
      {children}
    </ResizeObserverComponent>
  );
}
