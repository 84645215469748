import React, { ComponentProps, ComponentRef, ComponentType } from 'react';

const createAsyncComponent = <TComponent extends ComponentType<any>>(
  loader: () => Promise<{ default: TComponent }>,
  fallback = null
) => {
  const AsyncComponent = React.lazy(loader);

  const AsyncComponentLoader = React.forwardRef<
    ComponentRef<TComponent>,
    ComponentProps<TComponent>
  >((props, ref) => (
    <React.Suspense fallback={fallback}>
      {/* @ts-ignore */}
      <AsyncComponent {...props} ref={ref} />
    </React.Suspense>
  ));

  return AsyncComponentLoader;
};

export default createAsyncComponent;
