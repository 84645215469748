import { Link } from 'react-router-dom';
import { Button } from '../../../components/common/Button';
import { IconName } from '../../../components/common/Icon';
import ListPageItem from '../../../components/common/ListPageItem';
import { ListPageItemColor } from '../../../components/common/ListPageItem/ListPageItem';
import { PodmiotLink } from '../../../components/domain-common/PodmiotLink';
import { ParafialnaAnkietaKatechetycznaListItem } from '../../../lib/katecheza';
import { PodmiotTypId } from '../../../lib/misc';
import { Url } from '../../../router/urls';

export function ParafialneAnkietyKatechetyczneListItem({
  ankieta,
  index,
}: {
  ankieta: ParafialnaAnkietaKatechetycznaListItem;
  index: number;
}) {
  const detailsLink = Url.katecheza.parafialneAnkietyKatechetyczne.single(
    ankieta.id,
  );

  return (
    <ListPageItem
      label={ankieta.czyWypelnionaZaTenRok ? 'Wypełniona' : 'Niewypełniona'}
      bgColor={
        ankieta.czyWypelnionaZaTenRok
          ? ListPageItemColor.HappyGreen
          : ListPageItemColor.Orange
      }
    >
      <ListPageItem.IndexCol index={index + 1} />
      <ListPageItem.DetailsCol>
        <ListPageItem.DetailsRow>
          <Link to={detailsLink}>
            <ListPageItem.PrimaryDetail
              value={<>Parafia {ankieta.parafia.nazwa}</>}
            />
          </Link>
        </ListPageItem.DetailsRow>

        <ListPageItem.DetailsRow>
          <ListPageItem.Detail
            label="Diecezja"
            value={
              <PodmiotLink
                podmiot={{
                  id: ankieta.parafia.diecezja.id,
                  nazwa: ankieta.parafia.diecezja.nazwa,
                  typId: PodmiotTypId.Diecezja,
                }}
              />
            }
          />
          <ListPageItem.Detail
            label="Proboszcz"
            value={
              <PodmiotLink
                podmiot={{
                  id: ankieta.parafia.proboszcz.id,
                  nazwa: ankieta.parafia.proboszcz.nazwa,
                  typId: PodmiotTypId.Osoba,
                }}
              />
            }
          />
        </ListPageItem.DetailsRow>
      </ListPageItem.DetailsCol>

      <ListPageItem.ButtonsCol>
        {/* @ts-ignore */}
        <Button icon={IconName.Eye} as={Link as any} to={detailsLink}>
          Szczegóły
        </Button>
      </ListPageItem.ButtonsCol>
    </ListPageItem>
  );
}
