import Icon, { TIconName } from '../common/Icon';
import { FeatureIconName, IconName } from '../common/Icon/icons';

import classNames from 'classnames/bind';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactChild } from '../../helpers/types';
import { Url } from '../../router/urls';
import ProfilePicture from '../common/ProfilePicture';
import styles from './Sidebar.scss';

const cx = classNames.bind(styles);

export function Sidebar() {
  return (
    <div className="d-flex flex-column col-12 col-lg-3 col-xl-2 vh-100">
      <SidebarLogoSection />

      <div className="d-flex flex-column" style={{ overflowY: 'auto' }}>
        <SidebarEpiskopatLogoSection />

        <SidebarUserSection />

        <SidebarMenuSection />
      </div>
    </div>
  );
}

function SidebarLogoSection() {
  return (
    <div
      style={{ background: '#01808f', width: '100%', height: '3.375rem' }}
      className="d-flex justify-content-center "
    >
      <img src="/logo-synodi-white.svg" style={{ height: 50 }} />
    </div>
  );
}

function SidebarEpiskopatLogoSection() {
  return (
    <div
      style={{ width: '100%' }}
      className="d-flex py-3 flex-column justify-content-center"
    >
      <img src="/logo-kep.svg" style={{ height: 50 }} />

      <h4 className="text-center">Konferencja Episkopatu Polski</h4>
    </div>
  );
}

function SidebarUserSection() {
  return (
    <div className="px-3 py-3" style={{ background: '#6c757d' }}>
      <small className="text-white mb-3">Zalogowano jako:</small>

      <div
        style={{
          background: 'white',
          borderRadius: '240px 1.25rem 1.25rem 200px',
          paddingRight: '0.5rem',
          color: '#212529',
          boxShadow: 'rgba(0,0,0,.098) 0 0.625rem 0.625rem 0',
          height: 70,
          overflow: 'hidden',
        }}
        className="d-flex gap-3 align-items-center mt-2"
      >
        <ProfilePicture
          src={
            'https://upload.wikimedia.org/wikipedia/commons/0/08/Father_Mike_Schmitz.jpg'
          }
        />

        <div>Administrator systemu</div>
      </div>
    </div>
  );
}

function SidebarMenuSection() {
  return (
    <div
      className="py-3"
      style={{ background: '#6c757d', borderTop: '1px solid white' }}
    >
      <small className="px-3 text-white">Menu</small>

      <div>
        <SidebarMenuItem
          href={Url.home}
          icon={IconName.Home}
          label="Strona główna"
        />

        <SidebarMenuItem
          href={Url.diecezje.list}
          icon={IconName.LandPiece}
          label="Diecezje"
        />

        <SidebarMenuItem
          href={Url.home}
          icon={FeatureIconName.Parafia}
          label="Parafie"
        />

        <SidebarMenuItem
          href={Url.home}
          icon={FeatureIconName.Ksieza}
          label="Księża"
        />

        <SidebarMenuItem
          href={Url.home}
          icon={FeatureIconName.Biskupi}
          label="Biskupi"
        />

        <SidebarMenuItem
          href={Url.home}
          icon={IconName.MailOpen}
          label="Dekrety"
          disabled
        />

        <SidebarMenuItem
          href={Url.home}
          icon={IconName.CalendarEvent}
          label="Wydarzenia"
          disabled
        />

        <SidebarMenuItem
          href={Url.home}
          icon={IconName.MailUnread}
          label="Komunikaty"
          disabled
        />

        <SidebarMenuItem
          href={Url.home}
          icon={IconName.DocumentSharing}
          label="Wzory dokumentów"
          disabled
        />

        <SidebarMenuItem href={Url.home} label="Rady episkopalne" disabled />

        <SidebarMenuItem
          href={Url.home}
          label="Legitymacje"
          icon={FeatureIconName.Legitymacje}
          disabled
        />

        <SidebarMenuItem
          href={Url.finanse.index}
          label="Finanse"
          icon={FeatureIconName.Finanse}
        />

        <SidebarMenuItem
          href={Url.home}
          label="Registratura"
          disabled
          icon={FeatureIconName.Registratura}
        />

        <SidebarMenuItem
          href={Url.home}
          label="Nieruchomości"
          icon={IconName.Building}
          disabled
        />

        <SidebarMenuItem
          href={Url.home}
          label="Ruchomości"
          icon={IconName.Folders}
          disabled
        />

        <SidebarMenuItem href={Url.home} label="Placówki" disabled />

        <SidebarMenuItem
          href={Url.home}
          label="Osoby"
          disabled
          icon={FeatureIconName.Osoby}
        />

        <SidebarMenuItem
          href={Url.home}
          label="Kontrahenci"
          disabled
          icon={IconName.FinancialAgreement}
        />

        <SidebarCollapsableSection
          label="Wydział katechetyczny"
          icon={IconName.GraduationCap}
        >
          <SidebarMenuItem
            href={Url.home}
            label="Parafialne ankiety katechetyczne"
            icon={IconName.FactCheck}
          />
          <SidebarMenuItem
            href={Url.katecheza.parafialneAnkietyKatechetyczne.wyniki}
            label="Wyniki ankiet katechetycznych"
            icon={IconName.Chart}
          />
        </SidebarCollapsableSection>

        <SidebarCollapsableSection label="Formularze">
          <SidebarMenuItem
            href={Url.home}
            label="Parafialne"
            icon={IconName.Church}
            disabled
          />
        </SidebarCollapsableSection>

        <SidebarCollapsableSection label="Zarządzanie systemem">
          <SidebarMenuItem
            href={Url.home}
            label="Użytkownicy"
            icon={IconName.People}
            disabled
          />
        </SidebarCollapsableSection>
      </div>
    </div>
  );
}

function SidebarMenuItem({
  icon,
  label,
  href,
  disabled,
}: {
  label: string;
  href: string;
  icon?: TIconName;
  disabled?: boolean;
}) {
  return (
    <Link
      to={href}
      className={cx(
        'py-2 d-flex gap-3 align-items-center',
        !disabled && 'sidebar-menu-item',
        disabled && 'sidebar-menu-item-disabled',
      )}
      style={{ color: 'white', textDecoration: 'none' }}
      aria-disabled={disabled}
    >
      <span
        style={{ width: '20px', marginLeft: '20px' }}
        className="d-flex align-items-center"
      >
        {icon && <Icon icon={icon} />}{' '}
      </span>

      <span>{label}</span>
    </Link>
  );
}

function SidebarCollapsableSection({
  children,
  label,
  icon,
}: {
  children: ReactChild;
  label: string;
  icon?: TIconName;
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <div
        className={cx(
          'py-2 d-flex gap-3 align-items-center',
          'sidebar-menu-item',
        )}
        style={{
          color: 'white',
          textDecoration: 'none',
          cursor: 'pointer',

          background: isOpen ? 'rgba(0,0,0,0.4)' : '',
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          style={{ width: '20px', marginLeft: '20px' }}
          className="d-flex align-items-center"
        >
          {icon && <Icon icon={icon} />}{' '}
        </span>

        <span>{label}</span>

        <span style={{ paddingRight: '20px', marginLeft: 'auto' }}>
          <Icon
            size={13}
            icon={isOpen ? IconName.ChevronUp : IconName.ChevronDown}
          />
        </span>
      </div>

      {isOpen && (
        <div style={{ background: 'rgba(0,0,0,0.2)' }}>{children}</div>
      )}
    </>
  );
}
