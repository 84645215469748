import React from 'react';
import { Nil } from '../helpers/types';

// https://usehooks.com/usePrevious/
export function usePrevious<T>(value: T): T | null {
  const ref = React.useRef<T | null>(null);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useIsVisible() {
  const [el, setEl] = React.useState<HTMLElement>();
  const [isIntersecting, setIntersecting] = React.useState(false);

  React.useEffect(() => {
    if (el) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIntersecting(Boolean(entry?.isIntersecting));
        },
        { rootMargin: '0px' },
      );
      observer.observe(el);
      return () => {
        observer.unobserve(el);
      };
    }

    return () => null;
  }, [el]);

  const setRef = (x: HTMLElement | Nil) => {
    if (x) {
      setEl(x);
    }
  };

  const isVisibleBoolRef = React.useRef(isIntersecting);
  isVisibleBoolRef.current = isIntersecting;

  return { isVisible: isIntersecting, isVisibleBoolRef, ref: setRef };
}

export function useNewestNotNull<T>(value: T) {
  const [result, setResult] = React.useState(value);

  React.useEffect(() => {
    if (value) {
      setResult(value);
    }
  }, [JSON.stringify(value)]);

  return result;
}
