import { Nil } from './types';
import _throttle from 'lodash/throttle';

const getSearchString = _throttle(() => window.location.search, 100);
export function getQueryParams<T>(): T {
  return (Object.fromEntries(
    new URLSearchParams(getSearchString())
      //@ts-ignore
      .entries()
  ) as unknown) as T;
}

export function addQueryParamsToUrl(url: string, queryParams: QueryParams) {
  let result = url;
  for (const [key, value] of Object.entries(queryParams)) {
    result = addQueryParamToUrl({ url: result, key, value });
  }
  return result;
}

export function addQueryParamToUrl({
  url,
  key,
  value
}: {
  url: string;
  key: string;
  value: QueryParamValue;
}) {
  const paramPart = `${key}=${parseQueryParamValue(value)}`;

  if (url.includes('?') && !url.endsWith('&')) {
    return `${url}&${paramPart}`;
  } else if (url.includes('?') && url.endsWith('&')) {
    return `${url}${paramPart}`;
  } else {
    return `${url}?${paramPart}`;
  }
}

export type QueryParamValue = string | number | boolean | Nil;

export type QueryParams = {
  [key: string]: QueryParamValue;
};

export function parseQueryParams(
  input: QueryParams = {}
): Record<string, string> {
  const result: Record<string, string> = {};

  for (const [key, value] of Object.entries(input)) {
    const resolvedValue = parseQueryParamValue(value);
    if (!!resolvedValue) {
      result[key] = resolvedValue;
    }
  }

  return result;
}

function parseQueryParamValue(value: QueryParamValue): string {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'boolean') {
    return value ? '1' : '0';
  } else {
    return value?.toString?.() || '';
  }
}
