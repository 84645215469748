import { LoginForm } from './LoginForm';

export function LoginView() {
  return (
    <main className="d-flex flex-column vh-100 align-items-center justify-content-center">
      <div className="d-flex align-items-center">
        <img alt="KEP" src="/logo-kep.svg" style={{ width: 150 }} />

        <div className="h5 ">
          Konferencja <br />
          Episkopatu
          <br /> Polski
        </div>
      </div>

      <div
        className="shadow-lg border rounded py-4 px-4"
        style={{ width: 'min(100%, 500px)' }}
      >
        <h2 className="text-primary text-center">Logowanie do systemu</h2>

        <div>
          <LoginForm />
        </div>

        <div className="d-flex justify-content-end">
          <img alt="Synodi" src={'/logo-synodi.svg'} style={{ width: 130 }} />
        </div>
      </div>
    </main>
  );
}
