import { typedObjKeys } from '../../../helpers/types';

import { IconBaseProps } from 'react-icon-base';
import createAsyncComponent from '../../../helpers/createAsyncComponent';
import loadReactIconsKitIcon from './loadReactIconsKitIcon';

const reactIconsKitIcons = {
  // Material Design icons
  Plus: import('react-icons-kit/md/ic_add'),
  Minus: import('react-icons-kit/md/ic_remove'),
  Close: import('react-icons-kit/md/ic_close'),
  FindInPage: import('react-icons-kit/md/ic_find_in_page'),
  List: import('react-icons-kit/md/ic_list'),
  HamburgerMenu: import('react-icons-kit/md/ic_menu'),
  Reorder: import('react-icons-kit/md/ic_reorder'),
  Land: import('react-icons-kit/md/ic_texture'),
  Date: import('react-icons-kit/md/ic_date_range'),
  Power: import('react-icons-kit/md/ic_power_settings_new'),
  Gallery: import('react-icons-kit/md/ic_image'),
  Scanner: import('react-icons-kit/md/ic_scanner'),
  Filter: import('react-icons-kit/md/ic_filter_list'),
  Copy: import('react-icons-kit/md/ic_content_copy'),
  Refresh: import('react-icons-kit/md/ic_refresh'),
  SimCard: import('react-icons-kit/md/ic_sim_card'),
  Receipt: import('react-icons-kit/md/ic_receipt'),
  Paste: import('react-icons-kit/md/ic_content_paste'),
  Dollar: import('react-icons-kit/md/ic_attach_money'),
  Map: import('react-icons-kit/md/ic_map'),
  RadioButtonUnchecked: import('react-icons-kit/md/ic_radio_button_unchecked'),
  Person: import('react-icons-kit/md/ic_person'),
  MusicNote: import('react-icons-kit/md/ic_music_note'),
  ChildCart: import('react-icons-kit/md/ic_child_friendly'),

  // FontAwesome icons
  Building: import('react-icons-kit/fa/buildingO'),
  Cog: import('react-icons-kit/fa/cog'),
  Eye: import('react-icons-kit/fa/eye'),
  Bold: import('react-icons-kit/fa/bold'),
  Underline: import('react-icons-kit/fa/underline'),
  Italic: import('react-icons-kit/fa/italic'),
  AlignCenter: import('react-icons-kit/fa/alignCenter'),
  AlignLeft: import('react-icons-kit/fa/alignLeft'),
  AlignRight: import('react-icons-kit/fa/alignRight'),
  AlignJustify: import('react-icons-kit/fa/alignJustify'),
  Link: import('react-icons-kit/fa/link'),
  Upload: import('react-icons-kit/fa/upload'),
  Trash: import('react-icons-kit/fa/trash'),
  Info: import('react-icons-kit/fa/infoCircle'),
  Warning: import('react-icons-kit/fa/exclamationTriangle'),
  Danger: import('react-icons-kit/fa/exclamationCircle'),
  Success: import('react-icons-kit/fa/checkCircle'),
  ChevronUp: import('react-icons-kit/fa/chevronUp'),
  ChevronRight: import('react-icons-kit/fa/chevronRight'),
  ChevronDown: import('react-icons-kit/fa/chevronDown'),
  ChevronLeft: import('react-icons-kit/fa/chevronLeft'),
  AngleUp: import('react-icons-kit/fa/angleUp'),
  AngleRight: import('react-icons-kit/fa/angleRight'),
  AngleDown: import('react-icons-kit/fa/angleDown'),
  AngleLeft: import('react-icons-kit/fa/angleLeft'),
  AngleDoubleUp: import('react-icons-kit/fa/angleDoubleUp'),
  AngleDoubleRight: import('react-icons-kit/fa/angleDoubleRight'),
  AngleDoubleDown: import('react-icons-kit/fa/angleDoubleDown'),
  AngleDoubleLeft: import('react-icons-kit/fa/angleDoubleLeft'),
  Asterisk: import('react-icons-kit/fa/asterisk'),
  File: import('react-icons-kit/fa/fileO'),
  FileArchive: import('react-icons-kit/fa/fileArchiveO'),
  FileAudio: import('react-icons-kit/fa/fileAudioO'),
  FileCode: import('react-icons-kit/fa/fileCodeO'),
  FileExcel: import('react-icons-kit/fa/fileExcelO'),
  FileImage: import('react-icons-kit/fa/fileImageO'),
  FilePdf: import('react-icons-kit/fa/filePdfO'),
  FilePowerpoint: import('react-icons-kit/fa/filePowerpointO'),
  FileVideo: import('react-icons-kit/fa/fileVideoO'),
  FileWord: import('react-icons-kit/fa/fileWordO'),
  Home: import('react-icons-kit/fa/home'),
  GraduationCap: import('react-icons-kit/fa/graduationCap'),
  Phone: import('react-icons-kit/fa/phone'),
  Envelope: import('react-icons-kit/fa/envelope'),
  Globe: import('react-icons-kit/fa/globe'),
  Paperclip: import('react-icons-kit/fa/paperclip'),
  Edit: import('react-icons-kit/fa/edit'),
  Reply: import('react-icons-kit/fa/reply'),
  TrashO: import('react-icons-kit/fa/trashO'),
  WpForms: import('react-icons-kit/fa/wpforms'),
  FolderOpen: import('react-icons-kit/fa/folderOpen'),
  FileAlt: import('react-icons-kit/fa/fileText'),
  MapMarker: import('react-icons-kit/fa/mapMarker'),
  Print: import('react-icons-kit/fa/print'),
  Save: import('react-icons-kit/fa/save'),
  Handshake: import('react-icons-kit/fa/handshakeO'),
  Question: import('react-icons-kit/fa/questionCircle'),
  Heart: import('react-icons-kit/fa/heart'),
  Exchange: import('react-icons-kit/fa/exchange'),

  // Iconic icons
  Spreadsheet: import('react-icons-kit/iconic/spreadsheet'),
  Chart: import('react-icons-kit/iconic/chart'),

  //Entypo icons
  Water: import('react-icons-kit/entypo/water'),
  Medal: import('react-icons-kit/entypo/medal'),

  //Ionicons
  Card: import('react-icons-kit/ionicons/card'),

  //Feathericons
  CornerRightUp: import('react-icons-kit/feather/cornerRightUp'),

  //IcoMoon
  Calculator: import('react-icons-kit/icomoon/calculator'),

  //Linea
  PaperReceipt: import('react-icons-kit/linea/ecommerce_receipt'),

  //Typicons
  Merge: import('react-icons-kit/typicons/flowMerge'),
};

// Custom icons
const customIcons = {
  BrainIdea: 'BrainIdea' as const,
  Church: 'Church' as const,
  FaChurch: 'FaChurch' as const,
  Cathedral: 'Cathedral' as const,
  Priest: 'Priest' as const,
  PaperOut: 'PaperOut' as const,
  PaperIn: 'PaperIn' as const,
  Pray: 'FaPray' as const,
  Bible: 'FaBible' as const,
  LockedBook: 'LockedBook' as const,
  Teacher: 'Teacher' as const,
  ChessBoard: 'FaChessBoard' as const,
  Landmark: 'FaLandmark' as const,
  BroadcastTower: 'FaBroadcastTower' as const,
  Inventory: 'FaInventory' as const,
  Tumbstone: 'FaTumbstone' as const,
  Drogowskaz: 'Drogowskaz' as const,
  Inea: 'Inea' as const,
  Payment: 'Payment' as const,
  FinancialAgreement: 'FinancialAgreement' as const,
  People: 'People' as const,
  Bishop: 'Bishop' as const,
  Test: 'Test' as const,
  Eucharist: 'Eucharist' as const,
  Accounting: 'Accounting' as const,
  Books: 'Books' as const,
  Donut: 'Donut' as const,
  FoldersHierarchy: 'FoldersHierarchy' as const,
  GalleryDetail: 'GalleryDetail' as const,
  IncomeTax: 'IncomeTax' as const,
  Investor: 'Investor' as const,
  Money: 'Money' as const,
  PaymentBanknotes: 'PaymentBanknotes' as const,
  Slave: 'Slave' as const,
  WebCode: 'WebCode' as const,
  OpenBook: 'OpenBook' as const,
  FolderFilter: 'FolderFilter' as const,
  Cost: 'Cost' as const,
  MailOpen: 'MailOpen' as const,
  MailUnread: 'MailUnread' as const,
  ManBoy: 'ManBoy' as const,
  ClinicalHospital: 'ClinicalHospital' as const,
  Meeting: 'Meeting' as const,
  Folders: 'Folders' as const,
  DocumentSharing: 'DocumentSharing' as const,
  School: 'School' as const,
  CalendarEvent: 'CalendarEvent' as const,
  Classroom: 'Classroom' as const,
  Community: 'Community' as const,
  Pages: 'Pages' as const,
  Tradycje: 'Tradycje' as const,
  Translokaty: 'Translokaty' as const,
  FactCheck: 'FactCheck' as const,
  SocialCare: 'SocialCare' as const,
  Wedding: 'Wedding' as const,
  EucharistWine: 'EucharistWine' as const,
  Pigeon: 'Pigeon' as const,
  LandPiece: 'LandPiece' as const,
  ImportBox: 'ImportBox' as const,
  RockingChair: 'RockingChair' as const,
  Zakon: 'Zakon' as const,
  Notary: 'Notary' as const,
  PersonalGrowth: 'PersonalGrowth' as const,
  PersonalInformation: 'PersonalInformation' as const,
  OfficeChair: 'OfficeChair' as const,
  RaisedHand: 'RaisedHand' as const,
} as const;

export type TIconName =
  | keyof typeof customIcons
  | keyof typeof reactIconsKitIcons;
export type IconProps = Omit<IconBaseProps, 'ref'>;
export type TIconComponent = (props: IconProps) => any;

const icons: {
  [key in TIconName]?: TIconComponent;
} = {};

const addIcon = (name: TIconName, icon: TIconComponent) => {
  if (icons[name]) {
    throw new Error(
      `Trying to add an icon named ${name}, but it already exists`,
    );
  }

  icons[name] = icon;
};

const partialIconName: any = {};

typedObjKeys({
  ...reactIconsKitIcons,
  ...customIcons,
}).forEach((key) => {
  partialIconName[key] = key;
});

export const IconName = partialIconName as any as {
  [key in TIconName]: TIconName;
};

for (const iconName of typedObjKeys(reactIconsKitIcons)) {
  const promise = reactIconsKitIcons[iconName];

  addIcon(
    iconName,
    loadReactIconsKitIcon(() => promise),
  );
}

for (const iconName of typedObjKeys(customIcons)) {
  const fileName = customIcons[iconName];
  addIcon(
    iconName,
    //@ts-ignore
    createAsyncComponent(() => import(`./custom/${fileName}` as const)),
  );
}

export default icons as {
  [key in TIconName]: TIconComponent;
};

export const FeatureIconName = {
  PlikiEncji: IconName.File,
  Generalia: IconName.Info,
  Zaliczki: IconName.PaperReceipt,
  Registratura: IconName.FolderOpen,
  Ksieza: IconName.Priest,
  Biskupi: IconName.Bishop,
  Szafarze: IconName.Eucharist,
  Legitymacje: IconName.Card,
  Katecheci: IconName.Classroom,
  Parafia: IconName.Church,
  Szkoly: IconName.School,
  Osoby: IconName.People,
  Finanse: IconName.Payment,
  FinanseDokumenty: IconName.Books,
  Zobowiazania: IconName.PaymentBanknotes,
  Naleznosci: IconName.Slave,
  WierszeRozliczeniowe: IconName.Accounting,
  PozycjeRozliczeniowe: IconName.Money,
  Wizytacje: IconName.Test,
  DokumentyEncji: IconName.Paperclip,
  Tradycje: IconName.Tradycje,
  Translokaty: IconName.Translokaty,
  Zakon: IconName.Zakon,
  MisjeKatechetyczne: IconName.Notary,
  Katechisci: IconName.Teacher,
  SluzbaLiturgiczna: IconName.ManBoy,
  Organisci: IconName.MusicNote,
  DoradcyZyciaRodzinnego: IconName.ChildCart,
  DiakoniStali: IconName.RaisedHand,
};
