import { Layout } from '../../../components/Layout';
import { AsyncContent } from '../../../components/common/AsyncContent';
import LabeledInfo from '../../../components/common/LabeledInfo';
import { PodmiotLink } from '../../../components/domain-common/PodmiotLink';
import { queryKeys } from '../../../entrypoint/react-query';
import { getParafialnaAnkietaKatechetyczna } from '../../../lib/katecheza';
import { PodmiotTypId } from '../../../lib/misc';
import { useIdParam } from '../../../router/router';
import tabela1 from './tabela1.png';
import tabela2 from './tabela2.png';
import tabela3 from './tabela3.png';
import tabela4 from './tabela4.png';

export function KatechetycznaAnkietaParafialnaView() {
  const id = useIdParam();

  return (
    <Layout pageTitle="Ankieta katechetyczna parafii">
      <AsyncContent
        queryFn={() => getParafialnaAnkietaKatechetyczna(id)}
        queryKey={queryKeys.katecheza.ankietyParafialneKatechetyczne.single(id)}
        renderContent={(ankieta) => (
          <div>
            <LabeledInfo label="Parafia">
              <PodmiotLink
                podmiot={{
                  id: ankieta.parafia.id,
                  nazwa: ankieta.parafia.nazwa,
                  typId: PodmiotTypId.Parafia,
                }}
              />
            </LabeledInfo>

            <LabeledInfo label="Proboszcz">
              <PodmiotLink
                podmiot={{
                  id: ankieta.parafia.proboszcz.id,
                  nazwa: ankieta.parafia.proboszcz.nazwa,
                  typId: PodmiotTypId.Osoba,
                }}
              />
            </LabeledInfo>

            <LabeledInfo label="Diecezja">
              <PodmiotLink
                podmiot={{
                  id: ankieta.parafia.diecezja.id,
                  nazwa: ankieta.parafia.diecezja.nazwa,
                  typId: PodmiotTypId.Diecezja,
                }}
              />
            </LabeledInfo>

            <h2>Udzielone odpowiedzi:</h2>

            <img src={tabela1} />
            <img src={tabela2} />
            <img src={tabela3} />
            <img src={tabela4} />
          </div>
        )}
      />
    </Layout>
  );
}
