import { QueryKey, useQuery } from 'react-query';

import { ReactChild } from '../../helpers/types';
import { Skeleton } from './Skeleton';

export function AsyncContent<TResult>(props: {
  queryFn: () => Promise<TResult>;
  queryKey: QueryKey;
  renderContent: (item: TResult) => ReactChild;
}) {
  const { data, isError, refetch, error, isLoading } = useQuery(
    props.queryKey,
    props.queryFn,
  );

  if (isError && !isLoading) {
    return <div>Coś poszło nie tak</div>;
  }

  if (!data) {
    return (
      <div className="p-3">
        <Skeleton lines={4} height={100} />
      </div>
    );
  }

  return <>{props.renderContent(data)}</>;
}
